import React from 'react'
import { BoltSvg, ChainSvg, TowerSvg } from './FeatureHeadingSvgs';
import learn from '../assets/featuresImages/learn.png'
import collaborate from '../assets/featuresImages/collaborate.png'
import explore from '../assets/featuresImages/explore.png'
import team from '../assets/featuresImages/team.png'
import governance from '../assets/featuresImages/governance.png'
import enterprise from '../assets/featuresImages/Enterprise.png'

const FeatureHeading = ({ heading, description, svg }) => {
    return (
        <div className='md:min-w-[1128px] min-h-[83px] flex justify-center md:justify-around py-2'>
            <div className='flex flex-col gap-2 justify-start'>
                <p className='text-[27px] text-[#3F3F3F] font-semibold'>{heading}</p>
                <p className='text-[16px] text-[#828282]'>{description}</p>
            </div>
            {svg}
        </div>
    );
};

const FeatureCard = ({ src, alt, cardHeading, cardDesc, background, text, backgroundImage }) => {
    return (
        <div 
            className={`md:max-w-[549px] md:w-full w-[350px] md:min-h-[360px] rounded-[12px] border-[1px] border-[#8D75E6] flex flex-col items-center gap-4 py-2 px-4 ${background}`} 
            style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
            <img src={src} alt={alt} className='md:h-[241px] w-auto rounded-[12px] bg-contain' />
            <div className='flex flex-col gap-2 px-4'>
                <p className={`text-[14px] font-semibold ${text}`}>{cardHeading}</p>
                <p className={`text-[14px] ${text}`}>{cardDesc}</p>
            </div>
        </div>
    )
}

const Features = () => {
    return (
        <div className='min-h-[2086.16px] mt-[160px] flex flex-col items-center relative'>
            <p className='text-[20px] md:text-[60px] flex items-end '>
                <p className='mb-2'>Unlock your</p>
                <span className='bg-[#8D75E6] max-w-[504px] max-h-[121.19px] text-white rounded-lg flex flex-col justify-end py-2 relative'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60" fill="none" className='h-8 w-10 absolute top-[13px] left-[13px] hidden md:block'>
                        <path d="M7.66992 13C10.9836 13 13.6699 10.3137 13.6699 7C13.6699 3.68629 10.9836 1 7.66992 1C4.35621 1 1.66992 3.68629 1.66992 7C1.66992 10.3137 4.35621 13 7.66992 13Z" fill="#D7C4FA" />
                        <path d="M27.6699 13C30.9836 13 33.6699 10.3137 33.6699 7C33.6699 3.68629 30.9836 1 27.6699 1C24.3562 1 21.6699 3.68629 21.6699 7C21.6699 10.3137 24.3562 13 27.6699 13Z" fill="#D7C4FA" />
                        <path d="M47.6699 13C50.9836 13 53.6699 10.3137 53.6699 7C53.6699 3.68629 50.9836 1 47.6699 1C44.3562 1 41.6699 3.68629 41.6699 7C41.6699 10.3137 44.3562 13 47.6699 13Z" fill="#D7C4FA" />
                        <path opacity="0.9592" d="M41.7974 25.7031L25.7422 17.5034L29.4367 34.8331L31.8836 29.5415L36.8113 35.9108L40.8475 32.93L35.9201 26.5605L41.7974 25.7031Z" fill="#494949" stroke="#4D3E78" stroke-width="0.99591" stroke-miterlimit="10" stroke-linejoin="round" />
                    </svg>
                    <p>potential : learn,</p>
                </span>
            </p>
            <p className='text-white rounded-lg bg-[#8D75E6] py-2 px-4 text-[20px] md:text-[52px] z-10'> Teach, and Grow</p>
            <div className='border-[1px] items-center md:items-stretch w-auto border-[#A990F5] lg:px-[40px] md:max-h-[1969px] pt-[120px] pb-[40px] rounded-lg flex flex-col pl-3 md:pl-0 gap-8 absolute top-[80px] lg:top-[150px]'>
                <FeatureHeading heading='Transformative Learning' description='Connect with experts and master unique projects.' svg={<ChainSvg />} />
                <div className='flex justify-center flex-col gap-4 md:flex-row md:justify-evenly md:gap-0 sm:flex-wrap sm:items-center'>
                    <FeatureCard src={learn} cardHeading={'Level Up Your Tech Skills'} cardDesc={'Seekers engage in unique projects with guidance, gaining hands-on experience and standing out professionally'} background={'bg-white'} text={'text-[#4D3E78]'} />
                    <FeatureCard src={collaborate} cardHeading={'Unique Projects for Skill Mastery'} cardDesc='Sages share expertise through personalized sessions, enhancing their profiles and impacting the tech community' background={'bg-[#836BC3]'} text={'text-white'} />
                </div>
                <FeatureHeading heading='Expert Mentorship' description='Receive personalised feedback and career-enhancing insights.' svg={<BoltSvg />} />
                <div className='flex justify-center flex-col gap-4 md:flex-row md:justify-evenly md:gap-0 sm:flex-wrap sm:items-center'>
                    <FeatureCard src={explore} cardHeading={'Personalized Sessions with Industry Experts'} cardDesc={'Seekers receive tailored feedback, improving skills and increasing visibility to recruiters and potential employers'} background={'bg-[#2C1F69]'} text={'text-white'} />
                    <FeatureCard src={team} cardHeading={'Direct Access to Career Opportunities'} cardDesc='Sages offer valuable insights and mock interviews, helping Seekers and boosting their professional network' background={'bg-white'} text={'text-[#4D3E78]'} />
                </div>
                <FeatureHeading heading='Career Boost' description='Grow your skills and stand out to recruiters.' svg={<TowerSvg />} />
                <div className='flex justify-center flex-col gap-4 md:flex-row md:justify-evenly md:gap-0 sm:flex-wrap sm:items-center'>
                    <FeatureCard src={enterprise} cardHeading={'Transform Your Resume and Profile'} cardDesc='Both seekers and sages benefit from transparent feedback, fostering growth and attracting job opportunities' background={'bg-[#E5C1EA]'} text={'text-[#4D3E78]'}  />
                    <FeatureCard src={governance} cardHeading={'Feedback That Drives Career Growth'} cardDesc='Join Protut to enhance your skills, connect with experts, and advance your career effectively.' background={'bg-[#2C1F69]'} text={'text-white'}  />
                </div>
            </div>
        </div>
    )
}

export default Features
