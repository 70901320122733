import React from 'react';
import LandingPageNavbar from '../../landingPage/LandingPageNavbar';
import Footer from '../../landingPage/Footer';
const RefundAndReturn = () => {
  return (
    <div >
    <LandingPageNavbar/>
    <div className="max-w-full mx-auto p-6 bg-gradient-to-r from-violet-100 via-blue-100 to-gray-100 font-bvp rounded-lg mt-5">
      <h1 className="text-3xl font-bold mb-6">Return & Refund Policy</h1>
      <p className="mb-4">Last updated: 2024-08-09</p>
      <p className="mb-4">
        Thank you for shopping at Protut website.
      </p>
      <p className="mb-4">
        If, for any reason, You are not completely satisfied with a purchase, We invite You to review our policy on refunds and returns.
      </p>
      <p className="mb-4">
        The following terms are applicable for any service that You purchased with Us.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Interpretation and Definitions</h2>
      <h3 className="text-xl font-semibold mt-6 mb-2">Interpretation</h3>
      <p className="mb-4">
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>
      
      <h3 className="text-xl font-semibold mt-6 mb-2">Definitions</h3>
      <p className="mb-4">For the purposes of this Return and Refund Policy:</p>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Protut</li>
        <li><strong>Company</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Protut, TBI-KIET Group Of Institution Ghaziabad, 201206 </li>
        <li><strong>Services</strong> refer to the items offered for sale on the Service.</li>
        <li><strong>Orders</strong> mean a request by You to purchase Services from Us.</li>
        <li><strong>Service</strong> refers to the Application or the Website or both.</li>
        <li><strong>Website</strong> refers to Protut, accessible from <a href="https://protut.in" className="text-blue-500 underline">https://protut.in</a></li>
        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Your Order Cancellation Rights</h2>
      <p className="mb-4">
        You are entitled to cancel Your Order until developer accepts your request for services without giving any reasons.
      </p>
      <p className="mb-4">
        In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>By email: <a href="mailto:contact@protut.in" className="text-blue-500 underline">contact@protut.in</a></li>
        <li>By visiting this page on our website: <a href="https://protut.in/contact" className="text-blue-500 underline">https://protut.in/contact</a></li>
        <li>By phone number: +919415475721</li>
      </ul>
      <p className="mb-4">
        We will reimburse You no later than 5 days from the day on which We receive the returned Services. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Conditions for Returns</h2>
      <p className="mb-4">In order for the Services to be eligible for a return, please make sure that:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>You haven't started with the services</li>
        <li>You have informed Us about Your decision</li>
      </ul>
      <p className="mb-4">The following Services cannot be returned:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>If You have started taking the services</li>
        <li>If You have not informed Us about Your decision</li>
     
      </ul>
      <p className="mb-4">
        We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.
      </p>
      <p className="mb-4">
        Only regular priced Services may be refunded. Unfortunately, Services on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Gifts</h2>
      <p className="mb-4">
        If the Services were marked as a gift when purchased and then shipped directly to you, You’ll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.
      </p>
      <p className="mb-4">
        If the Services weren’t marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
      <p className="mb-4">If you have any questions about our Returns and Refunds Policy, please contact us:</p>
      <ul className="list-disc pl-6 mb-4">
        <li>By email: <a href="mailto:contact@protut.in" className="text-blue-500 underline">contact@protut.in</a></li>
        <li>By visiting this page on our website: <a href="https://protut.in/contact" className="text-blue-500 underline">https://protut.in/contact</a></li>
        <li>By phone number: +919415475721</li>
      </ul>
    </div>
    <Footer/>
      </div>
  );
}

export default RefundAndReturn;
