import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners'; // Import the spinner component
import '../styles/contactUs.css';

const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('learner');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Send Message');
  const [loading, setLoading] = useState(false); // State to manage the loader
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader when submitting the form
    setButtonText('Sending...');

    const data = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      role: role,
      phone: phone,
    };

    try {
      const response = await axios.post('https://protut-live.onrender.com/api/contact/create', data);
      // console.log('Response:', response.data);

      // Update button text and hide the loader
      setLoading(false);
      setButtonText('Message Sent');

      // Show confirmation message
      setShowConfirmation(true);

      // Revert button text to "Send Message" after 2 seconds
      setTimeout(() => {
        setButtonText('Send Message');
        setShowConfirmation(false);
      }, 2000);

    } catch (error) {
      console.error('Error:', error);
      // Handle the error (e.g., show an error message)
      setLoading(false);
      setButtonText('Send Message');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen relative">
      <form className="p-8 max-w-[90vh] w-full" onSubmit={handleSubmit}>
        <h2 className="text-[50px] lg:text-[68px] text-center text-[#7F69CE] font-semibold mb-2">Contact us</h2>
        <p className="text-center text-gray-500 mb-8 text-[24px]">Connect with us, share your thoughts</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-6">
          <div>
            <label className="block text-[#8D8D8D] text-[12px]">First Name</label>
            <input
              type="text"
              className="w-full mt-1 p-2 border-b-2 border-gray-300 focus:outline-none focus:border-[#7F69CE] bg-transparent"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-[#8D8D8D] text-[12px]">Last Name</label>
            <input
              type="text"
              className="w-full mt-1 p-2 border-b-2 border-gray-300 focus:outline-none focus:border-[#7F69CE] bg-transparent"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-[#8D8D8D] text-[12px]">Email</label>
            <input
              type="email"
              className="w-full mt-1 p-2 border-b-2 border-gray-300 focus:outline-none focus:border-[#7F69CE] bg-transparent"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-[#8D8D8D] text-[12px]">Phone Number</label>
            <input
              type="text"
              className="w-full mt-1 p-2 border-b-2 border-gray-300 focus:outline-none focus:border-[#7F69CE] bg-transparent"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2 text-[12px]">Are You?</label>
          <div className="flex items-center">
            <input
              type= "checkbox"
              name="role"
              value="learner"
              className="custom-radio mr-2"
              checked={role === 'learner'}
              onChange={handleRoleChange}
            />
            <label className="mr-4 text-gray-700">Seeker</label>
            <input
              type="checkbox"
              name="role"
              value="developer"
              className="custom-radio mr-2"
              checked={role === 'developer'}
              onChange={handleRoleChange}
            />
            <label className="text-gray-700">Sage</label>
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-[#8D8D8D] text-[12px]">Message</label>
          <textarea
            className="w-full mt-1 p-2 border-b-2 border-gray-300 focus:outline-none focus:border-[#7F69CE] bg-transparent"
            placeholder="Write your message.."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="text-center">
          <button className="bg-[#7F69CE] px-8 py-2 text-white rounded-lg font-semibold text-lg flex items-center justify-center" type="submit" disabled={loading}>
            {loading ? (
              <ClipLoader color="#ffffff" size={24} /> // Loader component
            ) : (
              buttonText
            )}
          </button>
        </div>
      </form>

      {showConfirmation && (
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-[#7F69CE] text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-1000">
          We've received your message, our team will connect with you soon.
        </div>
      )}
    </div>
  );
};

export default ContactUs;
