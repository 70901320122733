import React, { useState } from 'react';
import { GiTowerFlag, GiFireShield } from "react-icons/gi";
import { GrServices } from "react-icons/gr";
import { FaLaptopCode } from "react-icons/fa";
import { PiFinnTheHuman } from "react-icons/pi";
import { IoBook } from "react-icons/io5";
import FlagSvg from './FlagSvg';
import '../styles/tabContent.css'

const TabsContent = () => {
    const [activeTab, setActiveTab] = useState(0);

    const texts = [
        { text: "Learn", style: 'bold' },
        { text: "Connect", style: 'normal' },
        { text: "Mentoring", style: 'low-opacity' },
        { text: "Skill-building", style: 'bold' },
        { text: "Professional Growth", style: 'normal' },
        { text: "Development", style: 'low-opacity' },
        { text: "Industry Experts", style: 'bold' },
        { text: "Knowledge Sharing", style: 'normal' },
        { text: "Collaborative Learning", style: 'low-opacity' },
        { text: "Tech Skills", style: 'bold' },
        { text: "Career Advancement", style: 'normal' },
        { text: "Interactive Sessions", style: 'low-opacity' },
        { text: "Hands-on Training", style: 'bold' },
        { text: "Innovation", style: 'normal' },
        { text: "Networking", style: 'low-opacity' },
        { text: "Peer Learning", style: 'bold' },
        { text: "Expertise", style: 'normal' },
        { text: "Knowledge Expansion", style: 'low-opacity' },
    ];

    const tabs = [
        { name: "Select Domain", icon: <GiTowerFlag /> },
        { name: "Select Tools", icon: <GiFireShield /> },
        { name: "Choose Company Type", icon: <FaLaptopCode /> },
        { name: "Pick Sages", icon: <PiFinnTheHuman /> },
        { name: "Select Services", icon: <GrServices /> },
        { name: "Start Learning", icon: <IoBook /> },
        { name: "Get Feedback", icon: <IoBook /> },
    ];

    const content = [
        "Identify the field you want to master, be it frontend development, UI design, or project management.",
        "Choose the tools and technologies relevant to your chosen domain to get hands-on experience.",
        "Decide the type of company sages you wish to learn from.",
        "Select a sage from our extensive list who matches your learning needs and preferences.",
        "Choose the specific services and expertise the sage offers to tailor your learning experience.",
        "Begin your journey by engaging with the sage and accessing the learning resources.",
        "Receive feedback from expert sages and connect with users to enhance your experience."
    ];

    return (
        <div className='flex justify-center relative md:mt-[150px] p-6 lg:p-0'>
           <div className={`lg:max-w-[601px] min-h-[559px] bg-[#7F69CE] mt-[36px] flex rounded-xl md:rounded-l-xl md:rounded-r-none z-10 lg:absolute lg:left-[calc(50%-600px)]`}>
                <div className='flex flex-col justify-start min-w-[180px] lg:min-w-[231px]'>
                    <h1 className='text-[11px] text-white min-h-[93px] flex justify-center items-center border-b-2 font-semibold border-r-2 border-[#A990F5]'>Find Your Flow</h1>
                    <div className='min-h-[10px] border-r-2 border-[#A990F5]' />
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            className={`border-l-[10px] border-l-transparent border-r-2 border-[#A990F5] relative font-bold h-[48px] flex items-center lg:pl-6 py-5 justify-start text-white text-[13px] cursor-pointer ${activeTab === index ? 'bg-[#A990F5] bg-opacity-[30%] border-r-0 border-l-[#A990F5]' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            <span className='mr-2 h-[18px] w-[18px]'>{tab.icon}</span>
                            {tab.name}
                            {activeTab === index && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="31" viewBox="0 0 15 31" fill="none" className='absolute right-0'>
                                    <path d="M1 15.98L16 0.97998V30.98L1 15.98Z" fill="#7F69CE" stroke="#A990F5" />
                                </svg>
                            )}
                        </div>
                    ))}
                    <div className='h-full border-r-2 border-[#A990F5]' />
                </div>
                <div className='flex flex-col gap-4 justify-start pt-[46px] px-4'>
                    <FlagSvg />
                    <p className='text-md lg:text-[31px] text-white'>{content[activeTab]}</p>
                </div>
            </div>
            <div className='h-[559px] w-[708px] bg-[#6956A8] ml-[492px] md:rounded-r-xl rounded-xl relative overflow-hidden hidden lg:block'>
                <svg xmlns="http://www.w3.org/2000/svg" width="108" height="36" viewBox="0 0 108 36" fill="none" className='absolute'>
                    <g clipPath="url(#clip0_609_743)">
                        <path d="M108 35.5L0.5 0V35.5H108Z" fill="#4D3E78" stroke="#A990F5" />
                    </g>
                    <defs>
                        <clipPath id="clip0_609_743">
                            <rect width="108" height="36" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <div className='marquee-content flex gap-4 py-[120px] max-w-[708px]'>
                    <div>
                        {texts.map((item, index) => (
                            <span key={index} className={`text-[27px] p-[10px] text-white ${item.style}`}>
                                {item.text}
                            </span>
                        ))}
                    </div>
                    <div>
                        {texts.map((item, index) => (
                            <span key={`duplicate-${index}`} className={`text-[27px] px-[80px] text-white ${item.style}`}>
                                {item.text}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabsContent;
