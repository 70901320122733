import React from 'react'

const FlagSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="55" viewBox="0 0 92 55" fill="none">
            <path d="M65.2856 51.9647C79.9052 51.9647 91.7567 47.4712 91.7567 41.9281C91.7567 36.3851 79.9052 31.8916 65.2856 31.8916C50.666 31.8916 38.8145 36.3851 38.8145 41.9281C38.8145 47.4712 50.666 51.9647 65.2856 51.9647Z" fill="#32274B" />
            <path d="M87.2443 38.9441C87.2443 33.841 79.5218 29.7041 66.8361 29.7041C59.4534 29.7041 53.0069 33.2828 46.1222 33.2828C39.2374 33.2828 32.7329 29.7041 25.3502 29.7041C12.6646 29.7041 5 33.841 5 38.9441C5 39.5512 5 41.8481 5 42.4343C5 47.5373 12.1069 51.674 24.7925 51.674C32.1752 51.674 40.2854 48.0953 46.1222 48.0953C51.9588 48.0953 60.2342 51.674 67.6168 51.674C80.3023 51.674 87.2443 47.5373 87.2443 42.4343C87.2443 41.8524 87.2443 39.5557 87.2443 38.9441Z" fill="#2A7857" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M54.8206 47.5742H46.122H37.4233L34.2031 48.4906C34.3518 53.6186 46.122 53.1003 46.122 53.1003C46.122 53.1003 57.8923 53.6185 58.041 48.4906L54.8206 47.5742Z" fill="#49B889" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M54.8212 46.0878C54.8212 44.8133 50.9265 43.7803 46.1226 43.7803C41.3184 43.7803 37.4238 44.8133 37.4238 46.0878C37.4238 46.3478 37.4238 47.314 37.4238 47.5742C37.4238 48.8487 41.3184 49.8816 46.1226 49.8816C50.9265 49.8816 54.8212 48.8487 54.8212 47.5742C54.8212 47.314 54.8212 46.3478 54.8212 46.0878Z" fill="#2A7857" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M66.8361 29.7041C59.4534 29.7041 53.0069 33.2828 46.1222 33.2828C39.2374 33.2828 32.7329 29.7041 25.3502 29.7041C12.6646 29.7041 5 33.841 5 38.9441C5 44.0471 12.1069 48.1839 24.7925 48.1839C28.9519 48.1839 33.3419 47.048 37.4264 46.056C37.4259 46.0665 37.4236 46.0768 37.4236 46.0872C37.4236 47.3617 41.3182 48.3945 46.1223 48.3945C50.9263 48.3945 54.8209 47.3617 54.8209 46.0872C54.8209 46.0712 54.8176 46.0556 54.8164 46.0396C58.9662 47.0354 63.4344 48.1837 67.6169 48.1837C80.3025 48.1837 87.2445 44.047 87.2445 38.9439C87.2445 33.8409 79.522 29.7041 66.8361 29.7041Z" fill="#49B889" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M46.122 52.1264C39.5396 52.1264 34.2031 50.4981 34.2031 48.4902C34.2031 48.7697 34.2031 49.837 34.2031 50.1167C34.2031 52.1249 39.5396 53.7529 46.122 53.7529C52.7047 53.7529 58.041 52.1249 58.041 50.1167C58.041 49.837 58.041 48.7696 58.041 48.4902C58.041 50.4981 52.7048 52.1264 46.122 52.1264Z" fill="#2A7857" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M26.1036 45.2731C34.5723 45.2731 41.4376 42.3772 41.4376 38.805C41.4376 35.2328 34.5723 32.3369 26.1036 32.3369C17.6348 32.3369 10.7695 35.2328 10.7695 38.805C10.7695 42.3772 17.6348 45.2731 26.1036 45.2731Z" fill="#32274B" />
            <path d="M69.9454 45.2731C78.4141 45.2731 85.2794 42.3772 85.2794 38.805C85.2794 35.2328 78.4141 32.3369 69.9454 32.3369C61.4766 32.3369 54.6113 35.2328 54.6113 38.805C54.6113 42.3772 61.4766 45.2731 69.9454 45.2731Z" fill="#32274B" />
            <path d="M38.5661 34.9005C38.5661 31.5285 31.9379 28.7949 23.7615 28.7949C15.5854 28.7949 8.95703 31.5284 8.95703 34.9005C8.95703 35.542 8.95703 37.9395 8.95703 38.5808C8.95703 41.9529 15.5853 44.6868 23.7615 44.6868C31.9378 44.6868 38.5661 41.9531 38.5661 38.5808C38.5661 37.9395 38.5661 35.542 38.5661 34.9005Z" fill="#2A7857" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M23.7615 41.0069C31.9378 41.0069 38.566 38.2732 38.566 34.9009C38.566 31.5286 31.9378 28.7949 23.7615 28.7949C15.5852 28.7949 8.95703 31.5286 8.95703 34.9009C8.95703 38.2732 15.5852 41.0069 23.7615 41.0069Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M83.3005 34.9005C83.3005 31.5285 76.6724 28.7949 68.496 28.7949C60.3197 28.7949 53.6914 31.5284 53.6914 34.9005C53.6914 35.542 53.6914 37.9395 53.6914 38.5808C53.6914 41.9529 60.3197 44.6868 68.496 44.6868C76.6724 44.6868 83.3005 41.9531 83.3005 38.5808C83.3005 37.9395 83.3005 35.542 83.3005 34.9005Z" fill="#2A7857" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M68.4959 41.0069C76.6722 41.0069 83.3004 38.2732 83.3004 34.9009C83.3004 31.5286 76.6722 28.7949 68.4959 28.7949C60.3196 28.7949 53.6914 31.5286 53.6914 34.9009C53.6914 38.2732 60.3196 41.0069 68.4959 41.0069Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M26.382 38.135C31.7405 38.135 36.0844 36.4873 36.0844 34.4547C36.0844 32.4221 31.7405 30.7744 26.382 30.7744C21.0236 30.7744 16.6797 32.4221 16.6797 34.4547C16.6797 36.4873 21.0236 38.135 26.382 38.135Z" fill="#32274B" />
            <path d="M71.2141 38.135C76.5725 38.135 80.9164 36.4873 80.9164 34.4547C80.9164 32.4221 76.5725 30.7744 71.2141 30.7744C65.8556 30.7744 61.5117 32.4221 61.5117 34.4547C61.5117 36.4873 65.8556 38.135 71.2141 38.135Z" fill="#32274B" />
            <path d="M34.6095 31.1581C34.6095 29.2149 29.7525 27.6396 23.7608 27.6396C17.7691 27.6396 12.9121 29.2149 12.9121 31.1581C12.9121 31.7426 12.9121 33.8144 12.9121 34.3989C12.9121 36.342 17.7691 37.9174 23.7608 37.9174C29.7526 37.9174 34.6095 36.342 34.6095 34.3989C34.6095 33.8144 34.6095 31.7426 34.6095 31.1581Z" fill="#49B889" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M23.7608 34.6763C29.7524 34.6763 34.6095 33.1011 34.6095 31.158C34.6095 29.2148 29.7524 27.6396 23.7608 27.6396C17.7692 27.6396 12.9121 29.2148 12.9121 31.158C12.9121 33.1011 17.7692 34.6763 23.7608 34.6763Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M79.3438 31.1581C79.3438 29.2149 74.4868 27.6396 68.4952 27.6396C62.5035 27.6396 57.6465 29.2149 57.6465 31.1581C57.6465 31.7426 57.6465 33.8144 57.6465 34.3989C57.6465 36.342 62.5036 37.9174 68.4952 37.9174C74.4867 37.9174 79.3438 36.342 79.3438 34.3989C79.3438 33.8144 79.3438 31.7426 79.3438 31.1581Z" fill="#49B889" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M24.5485 31.9275C26.5126 31.9275 28.1048 31.4186 28.1048 30.7909C28.1048 30.1632 26.5126 29.6543 24.5485 29.6543C22.5844 29.6543 20.9922 30.1632 20.9922 30.7909C20.9922 31.4186 22.5844 31.9275 24.5485 31.9275Z" fill="#32274B" />
            <path d="M68.4952 34.6763C74.4867 34.6763 79.3438 33.1011 79.3438 31.158C79.3438 29.2148 74.4867 27.6396 68.4952 27.6396C62.5036 27.6396 57.6465 29.2148 57.6465 31.158C57.6465 33.1011 62.5036 34.6763 68.4952 34.6763Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M21.1836 30.5028C21.1836 31.0969 22.3378 31.5785 23.7615 31.5785C25.1852 31.5785 26.3394 31.0969 26.3394 30.5028V25.5168C26.3394 24.923 25.1852 24.4414 23.7615 24.4414C22.3378 24.4414 21.1836 24.923 21.1836 25.5168V30.5028Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M23.7615 26.5925C25.1853 26.5925 26.3394 26.1109 26.3394 25.517C26.3394 24.923 25.1853 24.4414 23.7615 24.4414C22.3378 24.4414 21.1836 24.923 21.1836 25.517C21.1836 26.1109 22.3378 26.5925 23.7615 26.5925Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M23.8087 25.9516C24.8292 25.9516 25.6564 25.6561 25.6564 25.2917C25.6564 24.9272 24.8292 24.6318 23.8087 24.6318C22.7882 24.6318 21.9609 24.9272 21.9609 25.2917C21.9609 25.6561 22.7882 25.9516 23.8087 25.9516Z" fill="#32274B" />
            <path d="M24.9704 24.6735C24.9704 25.3419 24.4288 25.59 23.7606 25.59C23.0924 25.59 22.5508 25.3419 22.5508 24.6735V6.34262C22.5508 5.67443 23.0924 5.13281 23.7606 5.13281C24.4288 5.13281 24.9704 5.67443 24.9704 6.34262V24.6735Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M69.275 31.9275C71.2392 31.9275 72.8314 31.4186 72.8314 30.7909C72.8314 30.1632 71.2392 29.6543 69.275 29.6543C67.3109 29.6543 65.7188 30.1632 65.7188 30.7909C65.7188 31.4186 67.3109 31.9275 69.275 31.9275Z" fill="#32274B" />
            <path d="M65.9102 30.5028C65.9102 31.0969 67.0644 31.5785 68.4882 31.5785C69.9119 31.5785 71.0661 31.0969 71.0661 30.5028V25.5168C71.0661 24.923 69.9119 24.4414 68.4882 24.4414C67.0644 24.4414 65.9102 24.923 65.9102 25.5168V30.5028Z" fill="#81D8B4" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M68.4882 26.5925C69.9119 26.5925 71.0661 26.1109 71.0661 25.517C71.0661 24.923 69.9119 24.4414 68.4882 24.4414C67.0644 24.4414 65.9102 24.923 65.9102 25.517C65.9102 26.1109 67.0644 26.5925 68.4882 26.5925Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M68.4884 25.9516C69.5088 25.9516 70.3361 25.6561 70.3361 25.2917C70.3361 24.9272 69.5088 24.6318 68.4884 24.6318C67.4679 24.6318 66.6406 24.9272 66.6406 25.2917C66.6406 25.6561 67.4679 25.9516 68.4884 25.9516Z" fill="#32274B" />
            <path d="M69.6989 24.6735C69.6989 25.3419 69.1574 25.59 68.4891 25.59C67.8209 25.59 67.2793 25.3419 67.2793 24.6735V6.34262C67.2793 5.67443 67.8209 5.13281 68.4891 5.13281C69.1574 5.13281 69.6989 5.67443 69.6989 6.34262V24.6735Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M30.9989 14.0748H22.8735C22.4989 14.0748 22.1953 13.7712 22.1953 13.3965V6.07177C22.1953 5.69712 22.4989 5.39355 22.8735 5.39355H30.9989V14.0748Z" fill="#FF6565" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M23.7613 5.31159C24.9519 5.31159 25.9171 4.34641 25.9171 3.15579C25.9171 1.96518 24.9519 1 23.7613 1C22.5707 1 21.6055 1.96518 21.6055 3.15579C21.6055 4.34641 22.5707 5.31159 23.7613 5.31159Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M68.4898 5.31159C69.6804 5.31159 70.6456 4.34641 70.6456 3.15579C70.6456 1.96518 69.6804 1 68.4898 1C67.2992 1 66.334 1.96518 66.334 3.15579C66.334 4.34641 67.2992 5.31159 68.4898 5.31159Z" fill="#DAF2E8" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" />
            <path d="M30.9981 9.12321C30.0315 9.12321 29.248 8.33973 29.248 7.37313C29.248 6.40653 30.0315 5.62305 30.9981 5.62305V9.12321Z" fill="#B35980" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M41.5672 6.69922H29.9263C29.5516 6.69922 29.248 7.0028 29.248 7.37744V14.7022C29.248 15.0769 29.5516 15.3804 29.9263 15.3804H41.5672L37.9493 10.9948L41.5672 6.69922Z" fill="#FF6565" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M61.2461 14.0748H69.3714C69.7461 14.0748 70.0497 13.7712 70.0497 13.3965V6.07177C70.0497 5.69712 69.7461 5.39355 69.3714 5.39355H61.2461V14.0748Z" fill="#FABE64" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M61.2461 9.12321C62.2127 9.12321 62.9962 8.33973 62.9962 7.37313C62.9962 6.40653 62.2127 5.62305 61.2461 5.62305V9.12321Z" fill="#B35980" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M50.6777 6.69922H62.3186C62.6933 6.69922 62.9969 7.0028 62.9969 7.37744V14.7022C62.9969 15.0769 62.6933 15.3804 62.3186 15.3804H50.6777L54.2956 10.9948L50.6777 6.69922Z" fill="#FABE64" stroke="#32274B" stroke-width="0.678221" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M24.9377 14.1553H22.5137V15.5618H24.9377V14.1553Z" fill="#32274B" />
            <path d="M69.7169 14.1553H67.293V15.5618H69.7169V14.1553Z" fill="#32274B" />
        </svg>
    )
}

export default FlagSvg;
