import React from 'react'
import '../styles/landingPage.css'
import user1 from '../assets/user1.png'
import { useNavigate } from 'react-router'

const TestimonialsIconSvg = () => {
  return (
    <div className='flex flex-col relative'>
      <svg xmlns="http://www.w3.org/2000/svg" width="68" height="70" viewBox="0 0 68 70" fill="none">
        <path d="M19.949 68.1369L60.7537 51.7213L64.2791 41.8543L48.4791 1.55133L8.0143 17.5336L3.41776 26.8216L19.9496 68.1402L19.949 68.1369ZM23.9678 36.3461L20.7756 28.2024L39.8435 20.6702L44.6439 32.9153L47.4112 39.9786L28.3433 47.5109L23.9678 36.3461Z" fill="#EB81AF" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M47.4137 39.9779L54.3997 37.2175L44.0318 10.7776L39.8427 20.6701L47.4103 39.9785L47.4137 39.9779Z" fill="#D46294" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M64.2761 41.8567L24.2565 57.6654L64.2761 41.8567Z" fill="black" />
        <path d="M64.2761 41.8567L24.2565 57.6654" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M50.8877 28.2602L45.7766 35.8005L47.4157 39.9774L54.4016 37.2171L50.8905 28.2562L50.8877 28.2602Z" fill="#32313B" />
        <path d="M20.7748 28.2028L13.7887 30.9632L24.2558 57.6674L28.343 47.5147L20.7754 28.2062L20.7748 28.2028Z" fill="#D976A2" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.41742 26.8213L44.0322 10.7774L3.41742 26.8213Z" fill="black" />
        <path d="M3.41742 26.8213L44.0322 10.7774" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.7242 41.0037L22.8354 33.4634L20.7748 28.2055L13.7887 30.9659L17.7248 41.0071L17.7242 41.0037Z" fill="#32313B" />
        <path d="M3.40018 26.8246L7.99672 17.5365L48.4615 1.55425L64.2587 41.8612L24.2424 57.6694L28.3257 47.5138L54.3795 37.2212L44.015 10.7807L3.40018 26.8246Z" fill="#F5D0E0" stroke="#32313B" stroke-width="0.900002" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="66" height="95" viewBox="0 0 66 85" fill="none" className='absolute top-[20px]'>
        <path d="M37.2639 84.9998C50.3598 84.9998 60.979 83.9438 60.979 82.6374C60.979 81.3309 50.3636 80.2749 37.2639 80.2749C24.1641 80.2749 13.5488 81.3309 13.5488 82.6374C13.5488 83.9438 24.1641 84.9998 37.2639 84.9998Z" fill="#32313B" />
      </svg>
    </div>
  )
}

const Testimonials = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='flex flex-col justify-center items-center mt-[800px] md:mt-0'>
        <p className='text-[20px] lg:text-[60px] font-semibold flex items-end '>
          <p className='mb-2'>Testimonials</p>
          <span className='bg-[#8D75E6] md:max-w-[504px] md:max-h-[121.19px] text-white rounded-lg flex flex-col justify-end py-2 relative'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60" fill="none" className='h-8 w-10 absolute top-[13px] left-[13px] hidden md:block'>
              <path d="M7.66992 13C10.9836 13 13.6699 10.3137 13.6699 7C13.6699 3.68629 10.9836 1 7.66992 1C4.35621 1 1.66992 3.68629 1.66992 7C1.66992 10.3137 4.35621 13 7.66992 13Z" fill="#D7C4FA" />
              <path d="M27.6699 13C30.9836 13 33.6699 10.3137 33.6699 7C33.6699 3.68629 30.9836 1 27.6699 1C24.3562 1 21.6699 3.68629 21.6699 7C21.6699 10.3137 24.3562 13 27.6699 13Z" fill="#D7C4FA" />
              <path d="M47.6699 13C50.9836 13 53.6699 10.3137 53.6699 7C53.6699 3.68629 50.9836 1 47.6699 1C44.3562 1 41.6699 3.68629 41.6699 7C41.6699 10.3137 44.3562 13 47.6699 13Z" fill="#D7C4FA" />
              <path opacity="0.9592" d="M41.7974 25.7031L25.7422 17.5034L29.4367 34.8331L31.8836 29.5415L36.8113 35.9108L40.8475 32.93L35.9201 26.5605L41.7974 25.7031Z" fill="#494949" stroke="#4D3E78" stroke-width="0.99591" stroke-miterlimit="10" stroke-linejoin="round" />
            </svg>
            <p>Hear from</p>
          </span>
        </p>
        <p className='text-white rounded-lg bg-[#8D75E6] py-2 px-4 text-[20px] lg:text-[52px] z-10'>our engaged Community.</p>
      </div>
      <div className='hidden lg:block'>
        <div className='flex justify-center relative mt-[250px] testimonials '>
          <div className='flex-col justify-between hidden md:flex p-[48px] max-w-[601px] min-h-[654px] border-[1px] border-[#D7C4FA] bg-[#FCF9F5] top-[-48px] lg:absolute lg:left-[calc(50%-675px)] rounded-l-xl z-10'>
            <TestimonialsIconSvg />
            <div className='flex flex-col gap-4'>
              <p className='text-[43px] text-[#4D3E78]'>Sounds…<br />
                hard to believe?</p>
              <p className='text-[16px] text-[#4D3E78]'>Our platform thrives on the success stories of both sages and seekers. Here are some testimonials from our community, highlighting the transformative experiences and valuable skills gained through our programs.</p>
            </div>
          </div>
          <div className='h-[654px] w-[792px] bg-[#FCF9F5] ml-[492px] rounded-r-xl relative overflow-hidden border-[1px] border-[#D7C4FA] testimonials-card'>
            <div className='w-[263.34px] h-[286px] flex flex-col gap-6 bg-[#E9F3E7] rounded-[8px] absolute top-[120px] left-[100px] border-[1px] border-[#1F7A57] p-4'>
              <p className='text-[#1F7A57] text-[14px]'>Even before the official launch, Protut’s platform helped me gain valuable skills. The feedback from early testers was incredible!</p>
              <div className='flex gap-4 items-center'>
                {/* <img src={user1} /> */}
                <div className='flex flex-col gap-2'>
                  <p className='text-[#1F7A57] font-semibold text-[19px]'>Aarna T.</p>
                  <p className='text-[#1F7A57] text-[12px]'>2nd year student</p>
                </div>
              </div>
            </div>
            <div className='w-[168px] h-[178px] flex flex-col gap-2 bg-[#F47E3F] rounded-[8px] absolute top-[20px] left-[385px] border-[1px] border-[#F47E3F] p-2'>
              <p className='text-white text-[10px] h-[112px]'>Testing Protut’s features was an eye-opener. The practical projects and expert guidance were praised by everyone. Can’t wait for the launch!</p>
              <div className='flex gap-2 items-center'>
                {/* <img src={user1} className='h-[27px] w-[27px]' /> */}
                <div className='flex flex-col gap-2'>
                  <p className='text-white font-semibold text-[12px]'>Aman M.</p>
                  <p className='text-white text-[8px]'>Freelancer</p>
                </div>
              </div>
            </div>
            <div className='w-[168px] h-[178px] flex flex-col gap-2 bg-[#E269A4] rounded-[8px] absolute top-[20px] left-[575px] border-[1px] border-[#F47E3F] p-2'>
              <p className='text-white text-[10px] h-[112px]'>Protut’s early access gave me hands-on experience with unique projects. The testers were impressed with the learning opportunities it offers</p>
              <div className='flex flex-col gap-2'>
                <p className='text-white font-semibold text-[12px]'>Rohit P.</p>
                <p className='text-white text-[8px]'>3rd Year Student</p>
              </div>
            </div>
            <div className='w-[263.33px] h-[190px] flex flex-col gap-2 bg-[#FEEDE0] rounded-[8px] absolute top-[220px] left-[385px] border-[1px] border-[#B74D1A] p-2'>
              <p className='text-[#B74D1A] font-semi-bold text-[14px] h-[102px]'>As a sage, the pre-launch experience with Protut was fantastic. The feedback highlighted the platform's potential to impact tech education</p>
              <div className='flex flex-col'>
                <p className='text-[#B74D1A] font-semibold text-[19px]'>Shruti K.</p>
                <p className='text-[#B74D1A] text-[12px]'>UI/UX Designer at MAANG</p>
              </div>
            </div>
            <div className='w-[263.33px] h-[174px] flex flex-col gap-2 bg-[#FEEBEE] rounded-[8px] absolute top-[440px] left-[288px] border-[1px] border-[#FFBFDB] p-2'>
              <p className='text-[#A54B7A] text-[14px]'>Testing Protut has been a great experience. The feedback from seekers was positive, and the platform's features are truly impressive</p>
              <div className='flex flex-col gap-2'>
                <p className='text-[#A54B7A] font-semibold text-[12px]'>Akash S.</p>
                <p className='text-[#A54B7A] text-[8px]'>Senior Product Manager</p>
              </div>
            </div>
            <div className='w-[168px] h-[174px] flex flex-col gap-2 bg-[#8D75E6] rounded-[8px] absolute top-[440px] left-[575px] border-[1px] border-[#8D75E6] p-2'>
              <p className='text-white text-[10px] h-[112px]'>The early feedback on Protut was very encouraging. Sages appreciated the innovative approach and are excited about the official launch</p>
              <div className='flex flex-col gap-2'>
                <p className='text-white font-semibold text-[12px]'>Tushar S.</p>
                <p className='text-white text-[8px]'>Software Developer</p>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="47" viewBox="0 0 72 47" fill="none" className='absolute bottom-0 left-0'>
              <g clip-path="url(#clip0_433_4208)">
                <mask id="mask0_433_4208" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="47">
                  <path d="M72 0H0V47H72V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_433_4208)">
                  <path d="M0.5 0.5V46.5H0.858L71.5 0.727V0.5H0.5Z" fill="#C2AAFA" stroke="#D7C4FA" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_433_4208">
                  <rect width="72" height="47" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div className='lg:hidden'>
      <div className='flex flex-col justify-center items-center gap-4 p-4'>
          <div className=' flex flex-col gap-6 bg-[#E9F3E7] rounded-[8px] border-[1px] border-[#1F7A57] p-4 max-w-[500px]'>
            <p className='text-[#1F7A57] text-[14px]'>Mentoring through this platform has been an incredibly rewarding experience. I've been able to share my knowledge and watch my mentees grow into confident developers ready to tackle real-world challenges.</p>
            <div className='flex gap-4 items-center'>
              <img src={user1} />
              <div className='flex flex-col gap-2'>
                <p className='text-[#1F7A57] font-semibold text-[19px]'>John D.</p>
                <p className='text-[#1F7A57] text-[12px]'>Frontend Developer at Startup</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-6 bg-[#F47E3F] rounded-[8px] border-[1px] border-[#F47E3F] p-4 max-w-[500px]'>
            <p className='text-white text-[14px] '>Mentoring through this platform has been an incredibly rewarding experience. I've been able to share my knowledge and watch my mentees grow into confident developers ready to tackle real-world challenges.</p>
            <div className='flex gap-2 items-center'>
              <img src={user1} />
              <div className='flex flex-col gap-2'>
                <p className='text-white font-semibold text-[19px]'>John D.</p>
                <p className='text-white text-[12px]'>Frontend Developer at Startup</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2 bg-[#E269A4] rounded-[8px] border-[1px] border-[#F47E3F] p-2 max-w-[500px]'>
            <p className='text-white text-[14px] h-[72px]'>Providing insights and real-world project management skills to enthusiastic seekers has been a game-changer for me. It's fulfilling to see my mentees excel in their professional journeys.</p>
            <div className='flex flex-col gap-2'>
              <p className='text-white font-semibold text-[19px]'>Michael R</p>
              <p className='text-white text-[12px]'>Project Manager at Service-based Company</p>
            </div>
          </div>
          <div className='flex flex-col gap-2 bg-[#FEEDE0] rounded-[8px] border-[1px] border-[#B74D1A] p-2 max-w-[500px]'>
            <p className='text-[#B74D1A] font-semi-bold text-[14px] h-[72px]'>Learning from experienced developers in the industry has been a game-changer for me. The hands-on guidance and practical knowledge I've gained are invaluable.</p>
            <div className='flex flex-col'>
              <p className='text-[#B74D1A] font-semibold text-[19px]'>Sarah K.</p>
              <p className='text-[#B74D1A] text-[12px]'>UI/UX Designer at MAANG</p>
            </div>
          </div>
          <div className='flex flex-col gap-2 bg-[#FEEBEE] rounded-[8px]  border-[1px] border-[#FFBFDB] p-2 max-w-[500px]'>
            <p className='text-[#A54B7A] text-[14px] h-[72px]'>The personalized mentoring and insights from top-tier designers have significantly improved my skills. I now feel confident in creating user-centric designs.</p>
            <div className='flex flex-col gap-2'>
              <p className='text-[#A54B7A] font-semibold text-[19px]'>Liam T</p>
              <p className='text-[#A54B7A] text-[12px]'>UI Design Enthusiast</p>
            </div>
          </div>
          <div className='flex flex-col gap-2 bg-[#8D75E6] rounded-[8px] border-[1px] border-[#8D75E6] p-2 max-w-[500px]'>
            <p className='text-white text-[14px] h-[72px]'>Connecting with industry experts has provided me with the knowledge and confidence to manage projects effectively. The learning experience here is unparalleled.</p>
            <div className='flex flex-col gap-2'>
              <p className='text-white font-semibold text-[19px]'>Olivia S</p>
              <p className='text-white text-[12px]'>Budding Project Manager</p>
            </div>
          </div>
          </div>
        </div>
      <div className='flex flex-col justify-center items-center gap-4 pt-[100px] pb-[350px] testimonials'>
        <p className='text-[#3A3A3A] text-[23px] text-center'>Protut’s here to redefine your career ride, where seekers, sages,<br />
        and recruiters collide. With unique projects and growth,success<br />
         is amplified—watch your future truly come alive!</p>
        <div className='flex gap-2 items-center'>
          <button className='bg-[#FCF9F5] p-2 text-[#6956A8] rounded-lg' onClick={()=>navigate('/waitlist-learner')}>
          Seekers Waitlist
          </button>
          <button className='border-2 border-[#3A3A3A] p-2 text-[#3A3A3A] rounded-lg' onClick={()=>navigate('/waitlist-mentor')}>
          Sages Waitlist
          </button>
        </div>
      </div>
    </>
  )
}

export default Testimonials
