import React, { useState, useRef } from 'react';

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`shadow-lg border-[#4A3AFF] rounded-[14px] p-6 lg:w-[896px] bg-white ${isOpen ? 'border-2 ' : 'border-0 '}`}>
      <div className="flex justify-between items-center">
        <h2 className="text-[22px] font-medium text-[#170F49]">{title}</h2>
        <button
          onClick={toggleAccordion}
          className={`focus:outline-none p-4 rounded-full ${isOpen ? 'bg-[#4A3AFF] text-white' : 'bg-white shadow-md text-[#4A3AFF]'}`}
        >
          <svg
            className={`w-6 h-6 transform transition-transform ${
              isOpen ? 'rotate-0' : 'rotate-[-90deg]'
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px' }}
        className="overflow-hidden transition-max-height duration-500 ease-in-out"
      >
        <div className="mt-4 text-[#6F6C90] text-[18px]">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
