import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti";
import { Spinner } from "react-activity"; // Import a spinner package (e.g., react-activity)
import "react-activity/dist/library.css"; // Import spinner styles

const projects = [
  {
    title: "Shape Future Talent",
    description:
      "Share your expertise and help mold the tech leaders of tomorrow, all while growing your own career",
  },
  {
    title: "Earn While You Teach",
    description:
      "Make extra income by sharing your knowledge. Get paid to mentor, without the hassle of traditional platforms",
  },
  {
    title: "Enhance Your Profile",
    description:
      "Connect with top recruiters and companies. Mentoring here is more than a side gig—it's a career boost",
  },
];

const WaitlistDeveloper = () => {
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [loading, setLoading] = useState(false); // State for loader
  const location = useLocation();

  const handleJoinClick = async () => {
    const role = location.pathname.includes("/waitlist-mentor")
      ? "developer"
      : "";

    const data = {
      role: role,
      email: email,
    };

    setLoading(true); // Show loader

    try {
      const response = await axios.post(
        "https://protut-live.onrender.com/api/mail/create",
        data
      );
      // console.log("Response:", response.data);

      // Show success message and confetti
      setShowMessage(true);
      setShowConfetti(true);
      setIsJoined(true);

      // Hide message and confetti after a delay
      setTimeout(() => {
        setShowMessage(false);
        setShowConfetti(false);
      }, 5000); // Hide after 5 seconds
    } catch (error) {
      console.error("Error:", error);
      // Handle the error, e.g., by showing an error message
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <>
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="w-2/5 h-20  bg-[#00e9fa] absolute rounded-[50%] blur-[70px] brightness-200 -rotate-[14deg] max-md:-top-6 -top-16 left-1/4"></div>
      <div className="w-1/2 h-16  bg-[#4b00a1] absolute rounded-[50%] blur-[60px]  -rotate-[16deg] top- left-1/4 z-10"></div>
      <div className="bg-[#0A0F24] h-[80vh] max-md:h-screen flex flex-col items-center justify-center text-center text-white my-auto">
        <div className="hidden max-md:block max-md:absolute max-md:top-3 z-10">
          <img
            src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
            alt="Protut Logo"
            className="h-8 bg-white rounded-sm"
          />
        </div>
        <div className="max-w-4xl mx-auto py-auto max-md:my-auto px-6 font-bvp">
          <div>
            <h1 className="text-6xl md:text-7xl max-sm:text-3xl max-md:text-4xl font-bold mb-4 leading-5">
              Make Impact, Grow Your Career with Us!
            </h1>
            <p className="mb-10 text-lg md:text-xl max-md:text-center max-md:w-full text-gray-500 mt-14">
              Mentor the next generation, sharpen your skills, and boost your
              profile. Ready to lead? Join the waitlist!
            </p>
          </div>
          <div className="w-full flex justify-center">
            <div className="bg-[#080808a5] shadow-xl bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-3xl p-8 text-center max-w-xl w-full mx-4 flex flex-col justify-center align-middle">
              <h2 className="text-3xl font-bold text-white mb-4">
                Join Protut's sages waitlist!
              </h2>
              <p className="text-gray-400 mb-8">
                After joining the waitlist, don't forget to fill out the form!
                Let's get you set up for success—quick and easy.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-1">
                <input
                  type="email"
                  placeholder="Enter email"
                  className="w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 p-2 rounded-full bg-black px-4 focus:outline-none border-[0.1px] border-gray-400"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
         
                />
                <button
                  type="submit"
                  className={`bg-white text-black font-semibold w-full px-2 py-2 rounded-full hover:bg-gray-200 transition-colors duration-200 shadow-white ${
                    isJoined ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={handleJoinClick}
                  disabled={isJoined || loading}
                >
                  {loading ? (
                    <Spinner size={16} color="#000" />
                  ) : isJoined ? (
                    "Joined"
                  ) : (
                    "Join "
                  )}
                </button>
                <button
                  className="bg-white text-black font-semibold w-full px-2 py-2 rounded-full  hover:bg-gray-200 transition-colors duration-200 shadow-white"
                  onClick={() =>
                    (window.location.href =
                      "https://forms.office.com/r/Qf3XYRpau9")
                  }
                  target="_blank"
                >
                  Fill Form
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMessage && (
        <div className="fixed bottom-10 w-full max-w-[1000px] text-center left-1/2 transform -translate-x-1/2 bg-[#4D5BCE] text-white py-4 px-6 rounded-full shadow-lg transition-opacity duration-700">
          🎉 You've successfully joined the waitlist! Please fill out the form
          to join Protut as a sage 🎉
        </div>
      )}

      <section className="bg-[#0A0F24] max-md:py-10 pb-64">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-xl font-bold text-[#4D5BCE]">
            Just A Gist
          </h2>
          <h1 className="text-center text-3xl font-extrabold text-white mt-2">
            What we are curating for you!
          </h1>
          <div className="mt-12 grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-[#1E2235] p-10 rounded-lg shadow-[#00F0FF] shadow-[0_1px_4px_2px_#00F0FF] transition-shadow duration-300 text-center border-2 border-[#00F0FF]"
              >
                <h3 className="text-xl font-semibold text-white">
                  {project.title}
                </h3>
                <p className="mt-2 text-gray-400">{project.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WaitlistDeveloper;
