import React from "react";
import logo from "./images.png";
import Footer from "../../landingPage/Footer";
import Navbar from "../../landingPage/LandingPageNavbar";

const About = () => {
  return (
    <div className="font-bvp">
      <Navbar />
      <div className="w-full flex flex-col md:flex-row lg:gap-20 lg:pl-20 bg-blue-50 items-center justify-center h-[60vh] p-8 relative mt-6">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start md:w-1/2">
          <h2 className="text-3xl md:text-6xl font-extrabold text-center md:text-left mb-4 md:mb-0 mt-2">
            Empowering Careers with Innovative Learning Solutions
          </h2>
        </div>
        <div className="flex justify-center md:justify-start md:w-1/2">
          <img
            src={logo}
            alt="logo"
            className="w-full max-w-sm md:max-w-md lg:max-w-lg h-auto object-contain"
          />
        </div>
      </div>
      <div className="text-xl w-full md:w-3/5 mx-auto mt-20">
        <p className="leading-8 mt-1 text-center ">
          At Protut, we’re on a mission to redefine the learning and mentorship
          landscape. Our platform connects ambitious seekers with seasoned
          industry professionals, creating opportunities for real-world
          experience and impactful career growth. By offering customized
          projects, personalized guidance, and direct access to recruiters, we
          empower users to enhance their skills and stand out in the competitive
          job market. For sage, Protut provides a chance to share expertise,
          earn extra income, and continue personal development. We're dedicated
          to fostering a vibrant community where learning meets innovation,
          ensuring every interaction leads to success and transformation.
        </p>
      </div>
      <div className="p-20 mt-12 grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="p-10 rounded-lg shadow-md transition-shadow duration-300 text-center bg-white border-2 border-purple-500">
          <h3 className="text-2xl font-semibold text-purple-700">
            Personalized Learning Journeys
          </h3>
          <p className="mt-2 text-gray-600">
            Create a unique path with tailored projects and expert sessions.
            Gain hands-on experience and industry insights to boost your
            career and make your resume shine.
          </p>
        </div>
        <div className="p-10 rounded-lg shadow-md transition-shadow duration-300 text-center bg-white border-2 border-purple-500">
          <h3 className="text-2xl font-semibold text-purple-700">
            Direct Recruiter Access
          </h3>
          <p className="mt-2 text-gray-600">
            Stand out with direct connections to top recruiters and companies.
            Showcase your skills and get noticed for exciting job
            opportunities and career advancement.
          </p>
        </div>
        <div className="p-10 rounded-lg shadow-md transition-shadow duration-300 text-center bg-white border-2 border-purple-500">
          <h3 className="text-2xl font-semibold text-purple-700">
            Flexible Mentoring Opportunities
          </h3>
          <p className="mt-2 text-gray-600">
            Sage can design their own services and earn based on their
            expertise. Enjoy a flexible schedule while making a significant
            impact on the next generation of professionals.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
