import React, { useState } from 'react';
import '../styles/navbar.css';
import { useNavigate } from 'react-router';

const LandingPageNavbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      {/* Desktop Version */}
      <div className='hidden md:block'>
        <div className='min-h-[37.75px] pt-6 grid grid-cols-4 items-center w-full px-4'>
          <img
            src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
            alt="logo"
            className="h-[29.66px] w-[117.56px] ml-6 md:max-w-28 lg:max-w-40 cursor-pointer"
            onClick={() => navigate('/')}
          />
          <div className='flex gap-2 justify-center col-span-2'>
            <div className='flex gap-6 px-4 bg-black rounded-md'>
              <button className='bg-black hover:bg-black text-sm h-[37.75px] text-white w-full max-w-[120px]'>Products(soon)</button>
              <button className='bg-black hover:bg-black text-sm h-[37.75px] text-white w-full max-w-[120px]'>Solutions(soon)</button>
              <button className='bg-black hover:bg-black text-sm h-[37.75px] text-white w-full max-w-[120px]'>Resources(soon)</button>
              <button className='bg-black hover:bg-black text-sm h-[37.75px] text-white w-full max-w-[60px]' onClick={() => navigate('/about')}>About</button>
            </div>
          </div>
          <div className='flex gap-2'>
            <button className='w-full max-[111.56px] h-[35.75px] bg-[#A990F5] rounded-md text-white truncate' onClick={() => navigate('/contact')}>Book a demo</button>
            <button className='w-full max-[111.56px] h-[35.75px] bg-[#FFF6F6] border-[1px] border-[#8D75E6] rounded-md text-[#8D75E6]' onClick={() => navigate('/waitlist-learner')}>Seekers</button>
            <button className="theme-button-1" onClick={() => navigate('/waitlist-mentor')}>
            Sages
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <nav className="bg-white border-gray-200 md:hidden">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img
              src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
              alt="logo"
              className="h-[29.66px] w-[117.56px] ml-6 md:max-w-28 lg:max-w-40"
              onClick={() => navigate('/')}
            />
          </a>
          <div className="flex md:order-2">
            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-search"
              aria-expanded={isDrawerOpen ? "true" : "false"}
              onClick={toggleDrawer}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Drawer */}
      {isDrawerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="absolute top-0 left-0 right-0 bg-white shadow-lg p-6 rounded-b-lg">
            <div className="flex items-center justify-between">
              <img
                src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
                alt="Protut logo"
                className="h-[29.66px] w-[117.56px]"
                onClick={() => navigate('/')}
              />
              <button
                className="text-black hover:text-gray-600"
                onClick={toggleDrawer}
              >
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-6 flex flex-col space-y-4">
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200">
                Products
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200">
                Solutions
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200">
                Resources
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200" onClick={() => { navigate('/about'); toggleDrawer(); }}>
                About
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200" onClick={() => { navigate('/contact'); toggleDrawer(); }}>
                Book a demo
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200" onClick={() => { navigate('/waitlist-learner'); toggleDrawer(); }}>
              Seekers
              </button>
              <button className="text-[#4A4A4A] bg-[#F5F5F5] hover:bg-[#E0DFF8] px-4 py-2 rounded-md transition-colors duration-200" onClick={() => { navigate('/waitlist-mentor'); toggleDrawer(); }}>
              Sages
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPageNavbar;
