import React, { useEffect, useState } from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import { useNavigate } from 'react-router';

const Cards = () => {
    const [showFirstCard, setShowFirstCard] = useState(false);
    const [showSecondCard, setShowSecondCard] = useState(false);
    const [showThirdCard, setShowThirdCard] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowFirstCard(true), 100);  // Delay for first card
        setTimeout(() => setShowSecondCard(true), 300); // Delay for second card
        setTimeout(() => setShowThirdCard(true), 500);  // Delay for third card
    }, []);

    return (
        <div className='md:block hidden'>
            <div className={`bg-white max-w-xs h-[135px] p-4 px-4 shadow-2xl rounded-lg flex-col absolute top-[210px] left-[40%] hidden md:flex z-20 transition-transform duration-300 ease-in-out hover:-translate-y-3 ${showFirstCard ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
                <div className='flex flex-col gap-2 flex-grow'>
                    <p className='text-lg font-bold'>Master New Skills</p>
                    <p className='text-xs font-semibold text-[#666666]'>Dive into hands-on one-to-one sessions and learn from top professionals.</p>
                </div>
            </div>
            <div className={`hidden md:flex bg-white max-w-xs h-[135px] pt-6 pb-2 px-4 shadow-2xl rounded-lg flex-col absolute top-[362px] left-[42%] z-20 transition-transform duration-300 ease-in-out hover:-translate-y-3 ${showSecondCard ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
                <div className='flex flex-col gap-2 flex-grow'>
                    <p className='text-lg font-bold'>Teach And Inspire</p>
                    <p className='text-xs font-semibold text-[#666666]'>Share your expertise with eager seekers. Create impactful sessions and shape the future of tech.</p>
                </div>
            </div>
            <div className={`hidden md:flex bg-white max-w-xs h-[135px] pt-6 pb-2 px-4 shadow-2xl rounded-lg flex-col absolute top-[509px] left-[44%] z-20 transition-transform duration-300 ease-in-out hover:-translate-y-3 ${showThirdCard ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
                <div className='flex flex-col gap-2 flex-grow'>
                    <p className='text-lg font-bold'>Connect And Succeed</p>
                    <p className='text-xs font-semibold text-[#666666]'>Network with industry professionals and recruiters to get opportunities.</p>
                </div>
            </div>
        </div>
    );
}

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col lg:flex-row p-4 mt-12 px-10 justify-center items-center gap-4 md:gap-0 md:justify-between md:mx-10'>
            <div className='flex flex-col gap-2 md:mt-64'>
                <div className='flex items-center text-sm border-2 border-[#D9D9D9] rounded-[28px] max-w-xs p-1 bg-[#EFEFEF]'>
                    <span className='border-2 rounded-[14px] px-2 border-[#D9D9D9]'>New</span> The future of tech hiring <IoIosArrowRoundForward />
                </div>
                <p className='hidden md:block text-5xl lg:text-6xl font-semibold'>
                    Collaborate. Build. <br /> Succeed.
                </p>
                <p className='md:hidden block text-2xl font-semibold whitespace-nowrap'>
                    Collaborate. Build. Succeed.
                </p>
                <p className='text-sm md:text-base text-[#6C6C6C] max-w-[500px] text-center lg:text-left'>Ready to stand out? Protut connects you with industry experts for unique projects, personalized sessions, and recruiters connections to help you land a job. Get job-ready and shine!</p>
                <div className='flex flex-col md:flex-row gap-2'>
                    <button
                        className='px-10 min-h-[42px] bg-[#F5F5FA] text-[#7F69CE] rounded-[32px] text-lg md:text-xl lg:text-2xl hover:shadow-inner'
                        style={{
                            boxShadow: '0 8px 16px rgba(127, 105, 206, 0.4)',
                            transition: 'box-shadow 0.3s ease-in-out',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow = 'inset 0 4px 8px rgba(127, 105, 206, 0.4)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(127, 105, 206, 0.4)';
                        }}
                        onClick={() => navigate('/waitlist-learner')}
                    >
                        For Seekers
                    </button>

                    <button
                        className='px-10 min-h-[42px] bg-[#F5F5FA] text-[#7F69CE] rounded-[32px] text-lg md:text-xl lg:text-2xl hover:shadow-inner'
                        style={{
                            boxShadow: '0 8px 16px rgba(127, 105, 206, 0.4)',
                            transition: 'box-shadow 0.3s ease-in-out',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow = 'inset 0 4px 8px rgba(127, 105, 206, 0.4)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(127, 105, 206, 0.4)';
                        }}
                        onClick={() => navigate('/waitlist-mentor')}
                    >
                        For Sages
                    </button>
                </div>
            </div>
            <div>
                <Cards />
                <div className='relative max-w-full min-h-[486px] rounded-md bg-[#F3F1EF] shadow-lg lg:mr-10 z-10 hover:z-30 transition-transform duration-300 ease-in-out hover:-translate-y-3'>
                    <div className='px-8 py-6 flex justify-center flex-col md:flex-row gap-4 md:gap-0 md:justify-between items-center md:items-end cursor-pointer relative'>
                        <div>
                            <p className='text-md lg:text-2xl font-semibold'>Total Transparency,<br />No Secrets</p>
                            <p className='text-[16px] max-w-[500px] text-[#6C6C6C]'>We’re all about honesty with both seekers and sages. No separate connections—just clear, open features. You decide!</p>
                        </div>
                        <img
                            src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
                            alt="logo"
                            className="h-[30px] w-[118px] md:max-w-28 lg:max-w-40 mx-4"
                        />
                    </div>
                    <div className='px-4 h-full relative'>
                        <p className='font-semibold h-8 w-32 rounded-t-md bg-white text-[8px] flex items-center justify-center'>Benefits to choose us</p>
                        <div className='bg-white pt-4 px-2 flex flex-col md:flex-row gap-6 justify-around h-full'>
                            <div className='flex flex-col justify-start gap-6 bg-[#F3F1EF] h-full p-2'>
                                <p className='text-sm md:text-base font-bold'>Benefits for Seeker</p>
                                <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='text-xs lg:text-sm text-[#454545] flex flex-col gap-6'>
                                    <li>Handcrafted projects to make your resume stand out.</li>
                                    <li>Solve queries by connecting anytime with experts.</li>
                                    <li>Flexible connections: minutes, hours, or even weeks.</li>
                                    <li>Choose your preferred sages and experts easily.</li>
                                    <li>Get noticed by recruiters and founders for jobs.</li>
                                    <li>Access unique projects tailored just for you.</li>
                                </ol>
                            </div>
                            <div className='flex flex-col justify-start gap-6 bg-[#F3F1EF] h-full p-2'>
                                <p className='text-sm md:text-base font-bold'>Benefits for Sage</p>
                                <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }} className='text-xs lg:text-sm text-[#454545] flex flex-col gap-6'>
                                    <li>Earn by sharing your expertise through mentoring.</li>
                                    <li>Get visible to seekers and build your brand.</li>
                                    <li>Share what you know, anytime.</li>
                                    <li>Manage your sessions on your own time.</li>
                                    <li>Meet new people, build your network.</li>
                                    <li>Access top-notch learning resources.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
