import React, { useState } from 'react'
import Accordion from './Accordion'

const ProductInfo = () => {
    const [activeTab, setActiveTab] = useState('learner');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='pt-[200px]'>
            <div className='flex gap-2 justify-center items-center mb-4'>
                <button
                    className={`px-10 min-h-[42px] rounded-[32px] text-[24px] hover:shadow-inner bg-[#F5F5FA] text-[#7F69CE]`}
                    style={{
                        boxShadow: activeTab === 'learner' ? 'inset 0 4px 8px rgba(127, 105, 206, 0.4)' : '0 8px 16px rgba(127, 105, 206, 0.4)',
                        transition: 'box-shadow 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                        if (activeTab !== 'learner') {
                            e.currentTarget.style.boxShadow = 'inset 0 4px 8px rgba(127, 105, 206, 0.4)';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (activeTab !== 'learner') {
                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(127, 105, 206, 0.4)';
                        }
                    }}
                    onClick={() => handleTabChange('learner')}
                >
                    Seekers
                </button>

                <button
                    className={`px-10 min-h-[42px] rounded-[32px] text-[24px] hover:shadow-inner bg-[#F5F5FA] text-[#7F69CE]`}
                    style={{
                        boxShadow: activeTab === 'developer' ? 'inset 0 4px 8px rgba(127, 105, 206, 0.4)' : '0 8px 16px rgba(127, 105, 206, 0.4)',
                        transition: 'box-shadow 0.3s ease-in-out',
                    }}
                    onMouseEnter={(e) => {
                        if (activeTab !== 'developer') {
                            e.currentTarget.style.boxShadow = 'inset 0 4px 8px rgba(127, 105, 206, 0.4)';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (activeTab !== 'developer') {
                            e.currentTarget.style.boxShadow = '0 8px 16px rgba(127, 105, 206, 0.4)';
                        }
                    }}
                    onClick={() => handleTabChange('developer')}
                >
                    Sages
                </button>
            </div>

            {activeTab === 'learner' && (
                <div className='flex flex-col justify-center items-center gap-10 p-10 lg:p-0'>
                    <Accordion
                        title="What will be the flow after release of protut version 1?"
                        content={<p>To begin your learning journey, sign up on our website and choose the domain you want to explore. Follow the step-by-step process to select tools, company types, and a sage to guide you. Finally, book the services you need and start learning.</p>}
                    />
                    <Accordion
                        title="How much do I need to pay?"
                        content={<p>Initially, nothing! It’s free to explore. Costs only apply to services you choose.</p>}
                    />
                    <Accordion
                        title="What makes Protut different for seekers?"
                        content={<p>Unlike others, we offer unique, personalised growth. No cookie-cutter content—just real, impactful learning.</p>}
                    />
                    <Accordion
                        title="Will I get placed using Protut?"
                        content={<p>Absolutely! We’re committed to your long-term career success, not just short-term placements.</p>}
                    />
                </div>
            )}

            {activeTab === 'developer' && (
                <div className='flex flex-col justify-center items-center gap-10 p-10 lg:p-0'>
                    <Accordion
                        title="How will I mentor seekers?"
                        content={<p>You create the services you want to offer. We handle the rest—easy and flexible!</p>}
                    />
                    <Accordion
                        title="Do I get paid for mentoring?"
                        content={<p>Yes, and the sky’s the limit! The more you give, the more you earn.</p>}
                    />
                    <Accordion
                        title="Do I need to pay anything as a sage?"
                        content={<p>Nope! Your contribution to the community is priceless. We won’t charge you a dime.</p>}
                    />
                    <Accordion
                        title="What’s in it for me?"
                        content={<p>Growth! Unlike other platforms, here you’ll evolve, learn new things, and avoid the monotony of repeating content.</p>}
                    />
                </div>
            )}
        </div>
    );
};

export default ProductInfo;
