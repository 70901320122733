import React from 'react'
import '../styles/landingPage.css'
import Header from './Header'
import TabsContent from './TabContent'
import ProductDemo from './ProductDemo'
import Features from './Features'
import Testimonials from './Testimonials'
import Pricing from './Pricing'
import ProductInfo from './ProductInfo'
import ContactUs from './ContactUs'
import Footer from './Footer'
import LandingPageNavbar from './LandingPageNavbar'

const LandingPage = () => {
  return (
    <div className='bg-[#EFEFEF]'>
      <div className='header'>
        <LandingPageNavbar />
        <Header />
        <TabsContent />
      </div>
      <ProductDemo />
      <Features />
      <Testimonials />
      <Pricing />
      <ProductInfo />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default LandingPage