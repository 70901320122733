import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti";

const projects = [
  {
    title: "Unique Projects Only Here",
    description:
      "Say goodbye to cookie-cutter assignments. Dive into exclusive, industry-relevant projects that make your resume shine.",
  },
  {
    title: "Instant Expert Help",
    description:
      "Get your questions answered on the spot. Connect with industry pros when you need them, no waiting around.",
  },
  {
    title: "Land Your Dream Job",
    description:
      "With tailored guidance and recruiter access, you're not just learning—you're paving the path to your next role.",
  },
];

const WaitlistLearner = () => {
  const [email, setEmail] = useState("");
  const [isJoined, setIsJoined] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleJoinClick = async () => {
    setLoading(true); // Start the loading state
    const role = location.pathname.includes("/waitlist-learner")
      ? "learner"
      : "";

    const data = {
      role: role,
      email: email,
    };

    try {
      const response = await axios.post(
        "https://protut-live.onrender.com/api/mail/create",
        data
      );
      // console.log("Response:", response.data);

      // Show success message and confetti
      setIsJoined(true);
      setShowMessage(true);
      setShowConfetti(true);

      // Hide message and confetti after a delay
      setTimeout(() => {
        setShowMessage(false);
        setShowConfetti(false);
      }, 5000); // Hide after 5 seconds
    } catch (error) {
      console.error("Error:", error);
      // Handle the error, e.g., by showing an error message
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

  return (
    <>
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="w-2/5 h-20 bg-[#00e9fa] absolute rounded-[50%] blur-[70px] brightness-200 -rotate-[14deg] max-md:-top-6 -top-16 left-1/4"></div>
      <div className="w-1/2 h-16 bg-[#4b00a1] absolute rounded-[50%] blur-[60px] -rotate-[16deg] left-1/4 z-10"></div>
      <div className="bg-[#0A0F24] h-[80vh] max-md:h-screen flex flex-col items-center justify-center text-center text-white">
        <div className="absolute top-5 right-5"></div>
        <div className="hidden max-md:block max-md:absolute max-md:top-3 z-10">
          <img
            src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png"
            alt="Protut Logo"
            className="h-8 bg-white rounded-sm"
          />
        </div>
        <div className="max-w-4xl mx-auto px-6 font-bvp">
          <div>
            <h1 className="text-6xl max-sm:text-3xl md:text-6xl max-md:text-4xl font-bold mb-4 leading-5">
              Level Up, Join the Future of Learning Today!
            </h1>
            <p className="mb-10 text-lg md:text-xl max-md:text-center max-md:w-full text-gray-500 mt-14">
              Unlock personalized projects, real-world experience, and direct
              recruiter connections. Ready to upgrade your skills? Join the
              waitlist!
            </p>
          </div>
          <div className="w-full flex justify-center">
            <div className="bg-[#080808a5] shadow-xl bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-3xl p-8 text-center max-w-xl w-full mx-4 flex flex-col justify-center align-middle">
              <h2 className="text-3xl font-bold text-white mb-4">
                Join Protut's seekers waitlist!
              </h2>
              <p className="text-gray-400 mb-8">
                Join our waitlist to get early access to our platform and some
                amazing perks!
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <input
                  type="email"
                  placeholder="Enter email"
                  className="w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 p-2 rounded-full bg-black px-4 focus:outline-none border-[0.1px] border-gray-400"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
         
                />
                <button
                  type="submit"
                  className={`bg-white text-black font-semibold px-3 py-2 rounded-full hover:bg-gray-200 transition-colors duration-200 shadow-white ${
                    isJoined || loading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={handleJoinClick}
                  disabled={isJoined || loading}
                >
                  {loading
                    ? "Joining..."
                    : isJoined
                    ? "Joined Successfully"
                    : "Join Waitlist"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Success Message */}
        {showMessage && (
          <div className="fixed bottom-10 w-full max-w-[1000px] left-1/2 transform -translate-x-1/2 bg-[#4D5BCE] text-white py-4 px-6 rounded-full shadow-lg transition-opacity duration-300">
            🎉 You've successfully joined the waitlist! 🎉
          </div>
        )}

        <div className="w-2/5 h-20 bg-[#00e9fa] absolute rounded-[50%] blur-[99px] brightness- -rotate-[55deg] top-full"></div>
      </div>

      <section className="bg-[#0A0F24] max-md:py-10 pb-60">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-xl font-bold text-[#4D5BCE]">
            Just A Gist
          </h2>
          <h1 className="text-center text-3xl font-extrabold text-white mt-2">
            What we are curating for you!
          </h1>
          <div className="mt-12 grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-[#1E2235] p-10 rounded-lg shadow-[#00F0FF] shadow-[0_1px_4px_2px_#00F0FF] transition-shadow duration-300 text-center border-2 border-[#00F0FF]"
              >
                <h3 className="text-xl font-semibold text-white">
                  {project.title}
                </h3>
                <p className="mt-2 text-gray-400">{project.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WaitlistLearner;
