import React, { useEffect, useRef } from 'react'
import '../styles/productDemo.css'
import '../assets/featuresVideo/FeatureVideo.mp4'
import { useNavigate } from 'react-router'

const ProductDemo = () => {
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(() => {

        const handleScroll = () => {
            const rect = videoRef.current.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
                videoRef.current.currentTime = 0; 
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='flex flex-col justify-center items-center md:mt-[150px] mt-[550px] gap-4 min-h-[1069.27px]'>
            <div className='flex flex-col gap-6 justify-center items-center relative px-2 md:px-0'>
                <h1 className='text-[43px]'>Meet The Product</h1>
                <p className='text-[16px] text-center'>Check out the video below for an <br /> exclusive sneak peek!</p>
                <div className='bg-[#3A3A3A] border-[12px] border-[#7F69CE] rounded-xl z-10 overflow-hidden max-w-[912px] max-h-[563px]'>
                    <video 
                        ref={videoRef}
                        className='w-full h-full object-cover' 
                        src={require('../assets/featuresVideo/FeatureVideo.mp4')} 
                        autoPlay
                        muted
                        loop
                        controlsList="nodownload noplaybackrate nofullscreen"
                    />
                </div>
                <div className='grid-container lg:min-w-[1174px] min-h-[714.42px] border-2 border-[#A990F5] rounded-xl absolute md:bottom-[-300px] bottom-[-400px]'>
                    <div className='flex flex-col gap-4 justify-end pb-[70px] min-h-[714.42px] items-center pt-[100px] px-2'>
                        <p className='text-[16px] lg:text-[23px] text-center'>Discover how Protut connects you with experts, offers unique projects, <br />and enhances your learning or teaching experience.<br /> Watch now!</p>
                        <div className='flex gap-2 items-center'>
                            <button className='bg-[#FCF9F5] p-2 text-[#6956A8] rounded-lg' onClick={()=>navigate('/waitlist-')}>
                                Seekers Waitlist
                            </button>
                            <button className='border-2 border-[#3A3A3A] p-2 text-[#3A3A3A] rounded-lg' onClick={()=>navigate('/waitlist-mentor')}>
                                Sages Waitlist
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDemo
