import React from 'react'
import '../styles/landingPage.css'

const PricingCard = ({ title, price, features, isPopular, description }) => {
    return (
        <div className="relative">
            <div className="group p-8 bg-white rounded-xl hover:shadow-xl transition-all duration-300 ease-in-out hover:bg-[#5243C2] hover:-translate-y-[80px]">
                <h3 className="text-[28px] font-semibold mb-2 text-[#231D4F] group-hover:text-white">
                    {title}
                </h3>
                <p className="text-[15px] font-semibold mb-6 text-[#848199] group-hover:text-white">
                    {description}
                </p>
                <ul className="mb-6 space-y-2">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center space-x-2 p-2">
                            <div className="flex-shrink-0">
                            {title === 'XYZ Company' ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <circle cx="10" cy="10" r="10" fill="#FF0000" className="group-hover:fill-white" fill-opacity="0.1" />
                                        <path
                                            d="M14.5 5.5L10 10L5.5 14.5M14.5 14.5L10 10L5.5 5.5"
                                            stroke="#FF0000"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="group-hover:stroke-white"
                                        />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path
                                            d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                                            fill="#5243C2"
                                            className="group-hover:fill-white"
                                            fill-opacity="0.103693"
                                        />
                                        <path
                                            d="M15.7722 6.83337L10.068 14.5742C9.93197 14.7547 9.72912 14.873 9.50503 14.9024C9.28094 14.9319 9.05441 14.8701 8.87634 14.7309L4.80301 11.4742C4.44356 11.1866 4.38536 10.662 4.67301 10.3025C4.96066 9.94309 5.48523 9.88489 5.84468 10.1725L9.24134 12.89L14.4305 5.84754C14.6007 5.5922 14.8974 5.45103 15.2029 5.48007C15.5083 5.50912 15.7731 5.70368 15.8921 5.98652C16.0111 6.26935 15.965 6.59469 15.7722 6.83337Z"
                                            fill="#5243C2"
                                            className="group-hover:fill-white"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span className="text-[#848199] text-[15px] group-hover:text-white">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};


const Pricing = () => {
    const plans = [
        {
            title: 'XYZ Company',
            // price: 10000,
            description: 'For Seekers ',
            features: [
                'Selling the same courses',
                'Free stuff sold for a fee',
                'Everyone ends with identical resumes',
                'Generic guidance for the masses',
                'Old projects, recycled for seekers',
            ],
        },
        {
            title: 'XYZ Company',
            // price: 50,
            description: 'For sages',
            features: [
                'No personal growth, just routine mentoring',
                ' High commission rates, little to earn',
                'Payments delayed, sages left waiting',
                'Boring, repetitive content, nothing new',
                'Stuck in a loop,and no growth',
            ],
        },
        {
            title: 'Protut',
            // price: 100,
            description: 'For Seekers',
            features: [
                'Unique resumes, every candidate stands out',
                'No gimmicks—just pure, honest learning',
                'Pay after you learn something',
                'Instant access to knowledge, no delays',
                'Direct connections with top recruiters',
            ],
            isPopular: true,
        },
        {
            title: 'Protut',
            // price: 500,
            description: 'For Sages',
            features: [
                'Fresh projects, never recycled content here',
                'Sages grow while helping others learn',
                'Fun, engaging mentoring, no boredom',
                'Instant transfer, no waiting around',
                'Self-growth while guiding future talent',
            ],
        },
    ];

    return (
        <>
            <div className='w-full min-h-[2500px] md:min-h-[1400px] bg-[#7F69CE] flex flex-col justify-center items-center relative px-10 py-20 '>
                <div className='lg:w-[1242px] lg:min-h-[1202px] flex flex-col gap-6 lg:gap-0 lg:justify-between items-center py-[70px] px-10 bg-[#F7F7FB] rounded-[20px] absolute top-[-200px]'>
                    <p className='text-[19px] font-semibold text-[#ACAEB1] text-center'>Why Us?</p>
                    <p className='text-[40px] lg:text-[75px] font-semibold text-[#7F69CE] text-center'>Why does Protut Stands <br />
                    Out From the Rest</p>
                    <div className='lg:w-[1170px] min-h-[467px] bg-white rounded-[26px] flex justify-center items-center gap-4 flex-wrap lg:flex-nowrap'>
                        {plans.map((plan, index) => (
                            <PricingCard key={index} {...plan} />
                        ))}
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center absolute lg:bottom-[-95px] bottom-0'>
                    <p className='text-[20px] lg:text-[60px] font-semibold flex items-end '>
                        <p className='mb-2 text-white pr-4'>Questions?</p>
                        <span className='bg-white max-w-[504px] max-h-[121.19px] text-[#8D75E6] rounded-lg flex flex-col justify-end py-2 relative px-6'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 60" fill="none" className='h-8 w-10 lg:absolute top-[13px] left-[13px] hidden lg:block'>
                                <path d="M7.66992 13C10.9836 13 13.6699 10.3137 13.6699 7C13.6699 3.68629 10.9836 1 7.66992 1C4.35621 1 1.66992 3.68629 1.66992 7C1.66992 10.3137 4.35621 13 7.66992 13Z" fill="#D7C4FA" />
                                <path d="M27.6699 13C30.9836 13 33.6699 10.3137 33.6699 7C33.6699 3.68629 30.9836 1 27.6699 1C24.3562 1 21.6699 3.68629 21.6699 7C21.6699 10.3137 24.3562 13 27.6699 13Z" fill="#D7C4FA" />
                                <path d="M47.6699 13C50.9836 13 53.6699 10.3137 53.6699 7C53.6699 3.68629 50.9836 1 47.6699 1C44.3562 1 41.6699 3.68629 41.6699 7C41.6699 10.3137 44.3562 13 47.6699 13Z" fill="#D7C4FA" />
                                <path opacity="0.9592" d="M41.7974 25.7031L25.7422 17.5034L29.4367 34.8331L31.8836 29.5415L36.8113 35.9108L40.8475 32.93L35.9201 26.5605L41.7974 25.7031Z" fill="#494949" stroke="#4D3E78" stroke-width="0.99591" stroke-miterlimit="10" stroke-linejoin="round" />
                            </svg>
                            <p>We’ve Got,</p>
                        </span>
                    </p>
                    <p className='text-[#8D75E6] font-semibold border-[1px] rounded-lg bg-white py-2 px-4 text-[20px] lg:text-[52px] z-10 mr-[150px]'>All the Best Answers!</p>
                </div>
            </div>
        </>
    )
}

export default Pricing
