import React from 'react';
import LandingPageNavbar from '../../landingPage/LandingPageNavbar';
import Footer from '../../landingPage/Footer';

const PrivacyPolicy = () => {
  return (
    <div >
    <LandingPageNavbar/>
    <div className="min-h-screen bg-gradient-to-r from-violet-100 via-blue-100 to-gray-100 py-10 font-bvp mt-5">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
      <div className="max-w-7xl mx-auto  p-8  rounded-lg">
        <p className="mb-4">
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>
        <p className="mb-4">
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <p className="mb-4">
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which are accessible at Protut unless otherwise
          defined in this Privacy Policy.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">
          Information Collection and Use
        </h2>
        <p className="mb-4">
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to Name, Email address, Phone Number, Crash
          Logs, Diagnostics Information . The information that we request will
          be retained by us and used as described in this privacy policy.
        </p>
        <p className="mb-4">
          The app does use third-party services that may collect information
          used to identify you. Link to the privacy policy of third-party
          service providers used by the app:
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Log Data</h2>
        <p className="mb-4">
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Cookies</h2>
        <p className="mb-4">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device’s internal
          memory.
        </p>
        <p className="mb-4">
          This Service does not use these “cookies” explicitly. However, the app
          may use third-party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Service Providers</h2>
        <p className="mb-4">
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p className="mb-4">
          We want to inform users of this Service that these third parties have
          access to their Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Security</h2>
        <p className="mb-4">
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.{" "}
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">
          Cookies and Web Beacons
        </h2>
        <p className="mb-4">
          Like any other website, Protut.in uses ‘cookies’. These cookies are
          used to store information including visitors’ preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users’ experience by customizing
          our web page content based on visitors’ browser type and/or other
          information.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </h2>
        <p className="mb-4">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>
            Request that a business that collects a consumer’s personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </li>
          <li>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </li>
          <li>
            Request that a business that sells a consumer’s personal data, not
            sell the consumer’s personal data.
          </li>
        </ul>
        <p className="mb-4">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">
          GDPR Data Protection Rights
        </h2>
        <p className="mb-4">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <ul className="list-disc pl-5 mb-4">
          <li>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </li>
          <li>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </li>
          <li>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </li>
        </ul>
        <p className="mb-4">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Children’s Information</h2>
        <p className="mb-4">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p className="mb-4">
          Protut.in does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">
          Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>
        <p className="mb-4">This policy is effective as of 2024-08-09</p>
      </div>
    </div>
          <Footer/>
          </div>
  );
};

export default PrivacyPolicy;
