import React from 'react'
import { Analytics } from '@vercel/analytics/react';
import LandingPage from './landingPage/LandingPage'
import WaitlistLearner from './waitlistPage/WaitlistLearner';
import WaitlistDeveloper from './waitlistPage/WaitlistDeveloper';
import About from './extraPages/aboutUs/About';
import ContactUs from './extraPages/contactUs/ContactUs';
import PrivacyPolicy from './extraPages/privacyPolicy/PrivacyPolicy';
import RefundAndReturn from './extraPages/refundAndReturn/RefundAndReturn';
import TermsAndConditions from './extraPages/termsAndCondition/TermsAndConditions';
import Pricing from './pricingSection/PricingSection'
import { BrowserRouter, Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <>
    <BrowserRouter>
     <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/waitlist-learner" element={<WaitlistLearner />} />
        <Route path="/waitlist-mentor" element={<WaitlistDeveloper />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/refund" element={<RefundAndReturn />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        {/* <Route path="/pricing" element={<Pricing />} /> */}

     </Routes>
     
    </BrowserRouter>
    <Analytics />
    </>
  )
}

export default App