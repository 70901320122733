import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useNavigate } from 'react-router';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer className="bg-[#7F69CE] text-white py-10 px-5">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-wrap justify-between gap-6">
                    <div className="mb-6 lg:mb-0 ">
                        <img src="https://res.cloudinary.com/djc9fpvmg/image/upload/v1717408549/Protut/Assets/Company_Image/logo_h0jkpx.png" alt="Protut Logo" className="h-8 bg-white" />
                    </div>

                    {/* Footer Columns */}
                    <div className="w-full lg:w-4/5 flex flex-wrap justify-between">
                        <div className="w-1/2 md:w-1/4 mb-6">
                            <h3 className="text-lg font-semibold mb-4">Company</h3>
                            <ul>
                                <li className="mb-2"><a  className=" cursor-pointer hover:text-gray-300" onClick={()=>navigate('/about')}  target="_blank">About</a></li>
                                <li className="mb-2"><a  className=" cursor-pointer hover:text-gray-300" onClick={()=>navigate('/contact')}  target="_blank">Contact</a></li>
                                <li className="mb-2"><a  className=" cursor-pointer hover:text-gray-300" onClick={()=>navigate('/contact')}  target="_blank">Book a demo</a></li>
                                <li className="mb-2"><a  className=" cursor-pointer hover:text-gray-300" >Community(Soon)</a></li>
                            </ul>
                        </div>

                        <div className="w-1/2 md:w-1/4 mb-6">
                            <h3 className="text-lg font-semibold mb-4">Resources(Soon)</h3>
                            <ul>
                                <li className="mb-2"><a  className="hover:text-gray-300">Help center</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">FAQ</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Feature Request</a></li>
                                <li><a  className="hover:text-gray-300">Careers</a></li>
                            </ul>
                        </div>

                        <div className="w-1/2 md:w-1/4 mb-6">
                            <h3 className="text-lg font-semibold mb-4">Product(Soon)</h3>
                            <ul>
                                <li className="mb-2"><a  className="hover:text-gray-300">Features</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Pricing</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Platform as a service</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Profiles</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300"> IT-Connect</a></li>
                            </ul>
                        </div>

                        <div className="w-1/2 md:w-1/4">
                            <h3 className="text-lg font-semibold mb-4">Use cases</h3>
                            <ul>
                                <li className="mb-2"><a  className="hover:text-gray-300">Recruiters connect</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Increase selection ratio</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Exclusive resumes</a></li>
                                <li className="mb-2"><a  className="hover:text-gray-300">Quick connect</a></li>
                                <li><a  className="hover:text-gray-300">Mentorship Guidance</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-between items-center mt-10">
                    <p className="text-sm hidden md:block text-gray-200">
                        © 2024 protut, Inc. All Rights Reserved.
                    </p>
                    <div className="flex space-x-4 items-center">
                        <a  className="cursor-pointer hover:text-gray-300" onClick={()=>navigate('/terms')}  target="_blank">Terms of Service</a>
                        <a  className="cursor-pointer hover:text-gray-300" onClick={()=>navigate('/privacy')}  target="_blank">Privacy Policy</a>
                        <a  className="cursor-pointer hover:text-gray-300" onClick={()=>navigate('/refund')}  target="_blank">Refund Policy</a>
                        <a href="https://www.linkedin.com/company/protutin/mycompany/" className="hover:text-gray-300"  target="_blank"><FaLinkedin /></a>
                        <a href="https://x.com/Protut_in" className="hover:text-gray-300" target="_blank"><FaTwitter /></a>
                        <a href="https://www.facebook.com/profile.php?id=61563617807658" className="hover:text-gray-300"  target="_blank"><FaFacebook /></a>
                        {/* <a  className="hover:text-gray-300"><FaYoutube /></a> */}
                    </div>
                    <p className="text-sm md:hidden block text-gray-200">
                        © 2024 protut, Inc. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
