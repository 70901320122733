import React from 'react';
import LandingPageNavbar from '../../landingPage/LandingPageNavbar';
import Footer from '../../landingPage/Footer';
const TermsAndConditions = () => {
  return (
    <div >
      <LandingPageNavbar/>
    <div className="min-h-screen bg-gradient-to-r from-violet-100 via-blue-100 to-gray-100 p-8 font-bvp mt-5 ">
        <h1 className=" text-4xl font-bold text-center text-black p-4">Terms & Conditions</h1>

      <div className=" p-6 rounded-lg ">
        <p className="text-left text-gray-800">
          Terms and conditions (“Terms”) are a set of legal terms defined by the owner of a website. They set forth the terms and conditions governing the activities of the website visitors on the said website and the relationship between the site visitors and the website owner. Terms must be defined according to the specific needs and nature of each website. For example, a website offering products to customers in e-commerce transactions requires Terms that are different from the Terms of a website only providing information. Terms provide the website owner the ability to protect themselves from potential legal exposure.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-2">In general, what should you cover in your Terms & Conditions?</h2>
        <ul className="list-disc list-inside text-gray-800">
          <li>Who can use your website; what are the requirements to create an account (if relevant)</li>
          <li>Key commercial Terms offered to customers</li>
          <li>Retention of right to change offering</li>
          <li>Warranties & responsibility for services and products</li>
          <li>Ownership of intellectual property, copyrights and logos</li>
          <li>Right to suspend or cancel member account</li>
          <li>Indemnification</li>
          <li>Limitation of liability</li>
          <li>Right to change and modify Terms</li>
          <li>Preference of law and dispute resolution</li>
          <li>Contact info</li>
        </ul>
        <p className="mt-6 text-gray-800">
          You can check out this support article to receive more information about how to create a Terms and Conditions page.
        </p>
        <p className="mt-4 text-gray-800">
          The explanations and information provided herein are only general explanations, information and samples. You should not rely on this article as legal advice or as recommendations regarding what you should actually do. We recommend that you seek legal advice to help you understand and to assist you in the creation of your privacy policy.
        </p>
      </div>
    </div>
      <Footer/>
      </div>
  );
}

export default TermsAndConditions;
