import React from 'react'

export const ChainSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="57" viewBox="0 0 94 57" fill="none">
            <g clip-path="url(#clip0_433_3471)">
                <path d="M63.324 46.0281C62.8391 46.0281 62.3541 45.8432 61.9841 45.4733L46.2544 29.7435C45.5141 29.0033 45.5141 27.8034 46.2544 27.0634C46.9946 26.3233 48.1943 26.3233 48.9345 27.0634L64.6643 42.7929C65.4045 43.533 65.4045 44.733 64.6643 45.4733C64.294 45.8432 63.8094 46.0281 63.324 46.0281Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M84.8814 36.556C89.3646 36.556 92.999 32.9216 92.999 28.4384C92.999 23.9552 89.3646 20.3208 84.8814 20.3208C80.3981 20.3208 76.7637 23.9552 76.7637 28.4384C76.7637 32.9216 80.3981 36.556 84.8814 36.556Z" fill="#FABE64" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M81.1405 26.3038C80.6556 26.3038 80.1706 26.119 79.8003 25.749L64.0709 10.0193C63.3306 9.27919 63.3306 8.07916 64.0709 7.3391C64.811 6.5992 66.0107 6.5992 66.7509 7.3391L82.4807 23.0687C83.2206 23.8088 83.2206 25.0088 82.4807 25.749C82.1104 26.119 81.6255 26.3038 81.1405 26.3038Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M65.9417 1.37988C70.425 1.37988 74.0591 5.01418 74.0591 9.49749C74.0591 13.9808 70.425 17.6151 65.9417 17.6151C61.458 17.6151 57.8242 13.9808 57.8242 9.49749C57.8242 5.01418 61.458 1.37988 65.9417 1.37988Z" fill="#FABE64" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M47.569 29.9161C48.0543 29.9161 48.5389 29.7312 48.9092 29.3613L64.639 13.6315C65.3789 12.8913 65.3789 11.6914 64.639 10.9514C63.8984 10.2115 62.6991 10.2115 61.9585 10.9514L46.2291 26.6809C45.4888 27.421 45.4888 28.621 46.2291 29.3613C46.599 29.731 47.084 29.9161 47.569 29.9161Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M32.5376 56.3446C36.6196 56.3446 39.9287 55.6657 39.9287 54.8283C39.9287 53.9909 36.6196 53.312 32.5376 53.312C28.4556 53.312 25.1465 53.9909 25.1465 54.8283C25.1465 55.6657 28.4556 56.3446 32.5376 56.3446Z" fill="#32274B" />
                <path d="M43.2402 26.3038C42.7552 26.3038 42.2702 26.119 41.8999 25.749L26.1702 10.0193C25.4303 9.27919 25.4303 8.07916 26.1702 7.3391C26.9108 6.5992 28.1101 6.5992 28.8507 7.3391L44.5801 23.0687C45.3203 23.8088 45.3203 25.0088 44.5801 25.749C44.2101 26.119 43.7252 26.3038 43.2402 26.3038Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M28.0592 1.37988C32.5425 1.37988 36.1766 5.01418 36.1766 9.49749C36.1766 13.9808 32.5425 17.6151 28.0592 17.6151C23.5755 17.6151 19.9414 13.9808 19.9414 9.49749C19.9414 5.01418 23.5755 1.37988 28.0592 1.37988Z" fill="#EB81AF" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M9.194 29.9157C9.67898 29.9157 10.164 29.7308 10.5343 29.3608L26.2637 13.6311C27.0039 12.8908 27.0039 11.691 26.2637 10.9509C25.5234 10.211 24.3237 10.211 23.5835 10.9509L7.85375 26.6805C7.11386 27.4206 7.11386 28.6206 7.85375 29.3608C8.22404 29.7306 8.70902 29.9157 9.194 29.9157Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M9.1178 36.5563C13.6011 36.5563 17.2356 32.9219 17.2356 28.4386C17.2356 23.9552 13.6011 20.3208 9.1178 20.3208C4.63447 20.3208 1 23.9552 1 28.4386C1 32.9219 4.63447 36.5563 9.1178 36.5563Z" fill="#EB81AF" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M27.7342 49.2056C27.2492 49.2056 26.7643 49.0205 26.394 48.6506L10.6646 32.921C9.9243 32.1808 9.9243 30.9808 10.6646 30.2405C11.4048 29.5006 12.6045 29.5006 13.3447 30.2405L29.0745 45.9703C29.8144 46.7105 29.8144 47.9105 29.0745 48.6506C28.7042 49.0205 28.2192 49.2056 27.7342 49.2056Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M28.0592 39.2627C32.5425 39.2627 36.1766 42.8972 36.1766 47.3802C36.1766 51.8638 32.5425 55.4981 28.0592 55.4981C23.5755 55.4981 19.9414 51.8638 19.9414 47.3802C19.9414 42.8972 23.5755 39.2627 28.0592 39.2627Z" fill="#EB81AF" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M10.0625 32.3555L11.01 36.4772L12.9998 35.4349L10.0625 32.3555Z" fill="#32274B" />
                <path d="M26.8832 13.1182L25.8409 17.1929L23.709 16.3873L26.8832 13.1182Z" fill="#32274B" />
                <path d="M40.1603 32.8081L30.0805 42.8877C29.3403 43.6277 29.3403 44.8278 30.0805 45.568C30.4508 45.9379 30.9355 46.1228 31.4208 46.1228C31.9058 46.1228 32.3908 45.9379 32.7607 45.568L42.8903 35.4384C41.7859 34.7883 40.8498 33.8852 40.1603 32.8081Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M40.1581 32.8081C40.1581 32.8081 41.87 35.1261 42.8881 35.4384L41.3791 36.8245C41.3791 36.8245 39.9263 35.3084 39.6738 33.3501L40.1581 32.8081Z" fill="#32274B" />
                <path d="M54.398 32.8081C54.398 32.8081 52.6861 35.1261 51.668 35.4384L53.177 36.8245C53.177 36.8245 54.6298 35.3084 54.8826 33.3501L54.398 32.8081Z" fill="#32274B" />
                <path d="M33.6764 17.4936C33.6764 17.4936 35.382 15.5351 35.6347 14.2718L35.0031 13.9561C35.0031 13.9561 33.6129 15.7881 32.5391 16.4199L33.6764 17.4936Z" fill="#32274B" />
                <path d="M71.5785 17.4936C71.5785 17.4936 73.2842 15.5351 73.537 14.2718L72.905 13.9561C72.905 13.9561 71.5153 15.7881 70.4414 16.4199L71.5785 17.4936Z" fill="#32274B" />
                <path d="M32.5195 46.1895L35.836 45.2418C35.836 45.2418 35.5044 43.9153 35.0306 43.3467L32.5195 46.1895Z" fill="#32274B" />
                <path d="M65.1962 13.4512L64.1855 17.1783C64.1855 17.1783 63.1117 17.1782 62.1641 16.3569L65.1962 13.4512Z" fill="#32274B" />
                <path d="M46.9987 36.5563C51.4821 36.5563 55.1165 32.9219 55.1165 28.4386C55.1165 23.9552 51.4821 20.3208 46.9987 20.3208C42.5154 20.3208 38.8809 23.9552 38.8809 28.4386C38.8809 32.9219 42.5154 36.5563 46.9987 36.5563Z" fill="#F0946C" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M69.43 56.3446C73.5119 56.3446 76.821 55.6657 76.821 54.8283C76.821 53.9909 73.5119 53.312 69.43 53.312C65.3481 53.312 62.0391 53.9909 62.0391 54.8283C62.0391 55.6657 65.3481 56.3446 69.43 56.3446Z" fill="#32274B" />
                <path d="M65.9417 39.2627C70.425 39.2627 74.0591 42.8972 74.0591 47.3802C74.0591 51.8638 70.425 55.4981 65.9417 55.4981C61.458 55.4981 57.8242 51.8638 57.8242 47.3802C57.8242 42.8972 61.458 39.2627 65.9417 39.2627Z" fill="#FABE64" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M77.9226 32.6201L68.6177 41.925C67.8774 42.6651 67.8774 43.865 68.6177 44.6052C68.988 44.9751 69.4726 45.1602 69.9579 45.1602C70.4429 45.1602 70.9279 44.9751 71.2978 44.6052L80.5804 35.3225C79.4961 34.6436 78.5831 33.7171 77.9226 32.6201Z" fill="#F5D0E0" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M77.9249 32.6201L77.2109 33.4736C77.2109 33.4736 77.9929 35.4719 79.2092 36.2539L80.2518 35.385L77.9249 32.6201Z" fill="#32274B" />
                <path d="M70.9141 45.3016L73.789 45.1013L72.9546 43.1172L70.9141 45.3016Z" fill="#32274B" />
                <path d="M77.1523 25.6253L80.6564 26.6245L77.8181 24.1772L77.1523 25.6253Z" fill="#32274B" />
            </g>
            <defs>
                <clipPath id="clip0_433_3471">
                    <rect width="94" height="56" fill="white" transform="translate(0 0.379883)" />
                </clipPath>
            </defs>
        </svg>
    )
}


export const BoltSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="81" viewBox="0 0 48 81" fill="none">
            <g clip-path="url(#clip0_433_3886)">
                <path d="M26.7899 45.1734L8.00586 39.0527L28.0164 32.1089L46.8006 38.2298L26.7899 45.1734Z" fill="#D67C56" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M19.6198 47.8084L0.835938 41.6877L20.8463 34.7441L39.6303 40.8648L19.6198 47.8084Z" fill="#FFE1B3" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M46.8007 38.2295L26.79 45.1732L26.7102 45.3486L19.6208 47.8088L5.26562 79.4579L12.4347 76.8221L46.8007 38.2295Z" fill="#D67C56" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M41.1306 1.15967L33.9615 3.79547L0.835938 41.6881L20.8463 34.7444L20.9197 34.5707L28.0155 32.1086L41.1306 1.15967Z" fill="#D67C56" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M0.835938 41.688L20.8463 34.7444L33.9615 3.79541L0.835938 41.688Z" fill="#FFD18C" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M19.6208 47.8084L39.6313 40.8647L5.26562 79.4576L19.6208 47.8084Z" fill="#FFD18C" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M21.3984 34.7702L26.6933 36.7211L33.6603 33.9343L28.0143 32.1089L21.3984 34.7702Z" fill="#32274B" />
                <path d="M13.0743 76.5098L5.0918 79.9739C7.09595 80.0928 9.34834 80.16 11.7306 80.16C20.0706 80.16 26.8315 79.3411 26.8315 78.3311C26.8317 77.3759 20.7844 76.5922 13.0743 76.5098Z" fill="#32274B" />
            </g>
            <defs>
                <clipPath id="clip0_433_3886">
                    <rect width="48" height="80" fill="white" transform="translate(0 0.159668)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const TowerSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="84" viewBox="0 0 114 84" fill="none">
            <g clip-path="url(#clip0_433_3949)">
                <path d="M69.6182 81.7566C93.7347 81.7566 113.285 77.581 113.285 72.43C113.285 67.279 93.7347 63.1035 69.6182 63.1035C45.5018 63.1035 25.9512 67.279 25.9512 72.43C25.9512 77.581 45.5018 81.7566 69.6182 81.7566Z" fill="#32274B" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M19 60.9746C19 66.5941 36.0136 71.1506 57.0005 71.1506C77.9883 71.1506 95.001 66.5941 95.001 60.9746L94.9031 39.063C94.9031 18.1298 77.9338 1.16016 57.0005 1.16016C36.0681 1.16016 19.0978 18.1298 19.0978 39.063L19 60.9746Z" fill="#A990F5" stroke="#6956A8" stroke-miterlimit="10" />
                <path d="M95.001 60.9709C95.001 55.3505 77.9883 50.7939 57.0005 50.7939C36.0136 50.7939 19 55.3505 19 60.9709C19 62.3198 19 69.2335 19 70.5834C19 76.2038 36.0136 80.7594 57.0005 80.7594C77.9883 80.7594 95.001 76.2038 95.001 70.5834C95.001 69.2335 95.001 62.3198 95.001 60.9709Z" fill="#7F69CE" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M57.0005 71.1459C77.9883 71.1459 95.001 66.5903 95.001 60.9699C95.001 55.3495 77.9883 50.7939 57.0005 50.7939C36.0136 50.7939 19 55.3495 19 60.9699C19 66.5903 36.0136 71.1459 57.0005 71.1459Z" fill="#A990F5" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M91.6918 57.7181C91.6918 53.7232 76.1579 50.4854 56.9968 50.4854C37.8357 50.4854 22.3027 53.7232 22.3027 57.7181C22.3027 58.2769 22.3027 60.4131 22.3027 60.9728C22.3027 64.9668 37.8357 68.2055 56.9968 68.2055C76.1579 68.2055 91.6918 64.9668 91.6918 60.9728C91.6918 60.4131 91.6918 58.2769 91.6918 57.7181Z" fill="#25A871" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M56.9968 64.9508C76.1588 64.9508 91.6918 61.7121 91.6918 57.7181C91.6918 53.7232 76.1588 50.4854 56.9968 50.4854C37.8357 50.4854 22.3027 53.7232 22.3027 57.7181C22.3027 61.7121 37.8357 64.9508 56.9968 64.9508Z" fill="#87D1A3" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M60.9099 57.3963C70.2393 57.3963 77.8012 56.2214 77.8012 54.7709C77.8012 53.3214 70.2393 52.1465 60.9099 52.1465C51.5814 52.1465 44.0195 53.3214 44.0195 54.7709C44.0195 56.2214 51.5814 57.3963 60.9099 57.3963Z" fill="#32274B" />
                <path d="M43.0762 54.3408C43.0762 55.828 49.3438 57.033 57.075 57.033C64.8062 57.033 71.0738 55.828 71.0738 54.3408V45.7263C71.0738 44.2392 64.8062 43.0342 57.075 43.0342C49.3438 43.0342 43.0762 44.2392 43.0762 45.7263V54.3408Z" fill="#A990F5" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M51.9609 56.7457V49.8695C51.9609 47.9817 53.4913 46.4512 55.3792 46.4512C57.2671 46.4512 58.7975 47.9817 58.7975 49.8695V56.868C58.7975 56.868 56.9284 56.9931 55.3227 56.9762C53.7396 56.9593 51.9609 56.7457 51.9609 56.7457Z" fill="#F486B8" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M53.3184 56.7336V49.8575C53.3184 48.4804 54.133 47.2933 55.307 46.7524" stroke="#32274B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M57.075 48.5512C64.8062 48.5512 71.0738 47.3453 71.0738 45.8582C71.0738 44.3719 64.8062 43.166 57.075 43.166C49.3438 43.166 43.0762 44.3719 43.0762 45.8582C43.0762 47.3453 49.3438 48.5512 57.075 48.5512Z" fill="#C2AAFA" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M68.5098 45.3831C68.5098 46.2692 63.3898 46.9869 57.0743 46.9869C50.7587 46.9869 45.6387 46.2692 45.6387 45.3831V24.5975C45.6387 23.7114 50.7587 22.9937 57.0743 22.9937C63.3898 22.9937 68.5098 23.7114 68.5098 24.5975V45.3831Z" fill="#D6EDD9" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M57.7189 46.9462H54.2188L57.8535 25.5435H61.3526L57.7189 46.9462Z" fill="#3F3F3F" />
                <path d="M68.5098 45.3831C68.5098 46.2692 63.3898 46.9869 57.0743 46.9869C50.7587 46.9869 45.6387 46.2692 45.6387 45.3831V24.5975C45.6387 23.7114 50.7587 22.9937 57.0743 22.9937C63.3898 22.9937 68.5098 23.7114 68.5098 24.5975V45.3831Z" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M68.5098 40.3589C68.5098 41.2441 63.3898 41.9627 57.0743 41.9627C50.7587 41.9627 45.6387 41.2441 45.6387 40.3589" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M68.5098 34.9209C68.5098 35.807 63.3898 36.5248 57.0743 36.5248C50.7587 36.5248 45.6387 35.807 45.6387 34.9209" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M68.5098 29.4824C68.5098 30.3676 63.3898 31.0862 57.0743 31.0862C50.7587 31.0862 45.6387 30.3676 45.6387 29.4824" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M71.0747 21.5989C71.0747 20.5717 64.8062 19.7383 57.0759 19.7383C49.3447 19.7383 43.0762 20.5717 43.0762 21.5989C43.0762 22.0109 43.0762 23.389 43.0762 23.801C43.0762 24.8291 49.3447 25.6616 57.0759 25.6616C64.8062 25.6616 71.0747 24.8291 71.0747 23.801C71.0747 23.389 71.0747 22.0109 71.0747 21.5989Z" fill="#A990F5" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M57.075 23.4586C64.8062 23.4586 71.0738 22.6261 71.0738 21.5989C71.0738 20.5717 64.8062 19.7383 57.075 19.7383C49.3438 19.7383 43.0762 20.5717 43.0762 21.5989C43.0762 22.6261 49.3438 23.4586 57.075 23.4586Z" fill="#D7C4FA" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M61.4531 46.6147V25.2129" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M59.4002 21.6426C63.3029 21.6426 66.4673 21.2353 66.4673 20.7339C66.4673 20.2326 63.3029 19.8262 59.4002 19.8262C55.4974 19.8262 52.334 20.2326 52.334 20.7339C52.334 21.2353 55.4974 21.6426 59.4002 21.6426Z" fill="#32274B" />
                <path d="M48.6348 46.3118V25.2129" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M62.6451 20.4309C62.6451 21.0997 60.1514 21.317 57.0755 21.317C53.9995 21.317 51.5059 21.0997 51.5059 20.4309C51.5059 19.7621 53.9995 16.9307 57.0755 16.9307C60.1514 16.9307 62.6451 19.7621 62.6451 20.4309Z" fill="#D6EDD9" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M57.6484 16.4785C57.6484 16.7964 57.3906 16.851 57.0736 16.851C56.7557 16.851 56.498 16.7955 56.498 16.4785V11.2738C56.498 10.9561 56.7557 10.6987 57.0736 10.6987C57.3906 10.6987 57.6484 10.9561 57.6484 11.2738V16.4785Z" fill="#D6EDD9" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M57.0738 11.9811C57.7445 11.9811 58.2882 11.4374 58.2882 10.7665C58.2882 10.0959 57.7445 9.55225 57.0738 9.55225C56.4031 9.55225 55.8594 10.0959 55.8594 10.7665C55.8594 11.4374 56.4031 11.9811 57.0738 11.9811Z" fill="#F486B8" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M48.5645 25.4808C48.5645 25.4808 61.8907 26.2211 68.3511 24.875V26.2879C68.3511 26.2879 53.612 26.8259 48.5645 25.4808Z" fill="#32274B" />
                <path d="M52.3125 49.9188L53.3218 49.8623L53.5918 48.5416L52.3125 48.4429V49.9188Z" fill="#32274B" />
                <path d="M49.6879 58.5802C49.6879 59.4475 46.959 60.1502 43.5934 60.1502C40.2268 60.1502 37.498 59.4475 37.498 58.5802C37.498 57.7139 40.2268 57.0112 43.5934 57.0112C46.959 57.0112 49.6879 57.7139 49.6879 58.5802Z" fill="#32274B" />
                <path d="M44.9307 57.0164L43.4416 56.0861V44.9158C43.4755 44.8735 40.6103 46.545 40.6103 46.545V56.0861L38.7873 57.2101C37.8645 57.8009 37.3105 58.0831 37.3105 58.322C37.3105 58.908 39.4213 59.3821 42.026 59.3821C44.6306 59.3821 46.7405 58.908 46.7405 58.322C46.7405 58.0831 45.7726 57.4914 44.9307 57.0164Z" fill="#1F7A57" stroke="#32274B" stroke-miterlimit="10" />
                <path d="M45.9832 37.9731C46.1789 37.4831 46.2899 36.9506 46.2899 36.391C46.2899 34.0365 44.3803 32.1279 42.0268 32.1279C39.6733 32.1279 37.7628 34.0365 37.7628 36.391C37.7628 36.9506 37.8738 37.4831 38.0695 37.9731C36.3838 39.1988 35.2852 41.1864 35.2852 43.4299C35.2852 47.153 38.3037 50.1715 42.0268 50.1715C45.7499 50.1715 48.7685 47.153 48.7685 43.4299C48.7685 41.1864 47.6698 39.1988 45.9832 37.9731Z" fill="#87D1A3" stroke="#32274B" stroke-miterlimit="10" stroke-linejoin="round" />
                <path d="M40.5508 50.0005V50.9487C40.5508 50.9487 40.9666 51.8978 41.9157 51.8978C42.8638 51.8978 43.5769 51.3645 43.5769 51.3645L43.4574 50.0005C43.4574 50.0005 41.2629 50.5941 40.5508 50.0005Z" fill="#32274B" />
                <path d="M30.0557 24.596C29.7547 24.596 29.4509 24.5292 29.163 24.389C28.149 23.8952 27.7267 22.6752 28.2196 21.6611C28.3259 21.442 30.8948 16.2232 36.0176 11.7024C36.8642 10.9558 38.1557 11.0369 38.9007 11.883C39.6476 12.7287 39.5676 14.0202 38.721 14.7661C34.2369 18.7225 31.9163 23.4023 31.8938 23.4493C31.5391 24.1746 30.8111 24.596 30.0557 24.596Z" fill="#FCF9F5" />
            </g>
            <defs>
                <clipPath id="clip0_433_3949">
                    <rect width="114" height="83" fill="white" transform="translate(0 0.160156)" />
                </clipPath>
            </defs>
        </svg>
    )
}

